import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";
// import { State } from "../../constant/Interface";
import services from "../../services/services";

const initialState: any = {
  data: [],
  tour: "",
  StopDetails: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const getsingleTourSlice = createAsyncThunk(
  "singleTourSlice/getsingleTourSlice",
  async (endpiont: string | number, { dispatch }) => {
    return await services.singletourList(endpiont);
  }
);

const singleTourSlice = createSlice({
  name: "singleTourSlice",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.tour = action.payload;
    },
    setStopDetails: (state: any, action) => {
      state.StopDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getsingleTourSlice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getsingleTourSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload?.data;
      })
      .addCase(getsingleTourSlice.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { setId, setStopDetails } = singleTourSlice.actions;
export default singleTourSlice.reducer;
