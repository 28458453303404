import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Images from "../../constant/Images";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function CheckBox({
  data,
  handleFilterBundleTours,
  handleAddBunleTours,
  tourInBundle,
  bundleTours,
}: any) {
  const handleChange = (e: any, elm: any) => {

   

  
    if (e.target.checked) {
      // elm.bundleInTourId = null;
      handleAddBunleTours({...elm,bundleInTourId : null});
      
      
    } else {
    
      handleFilterBundleTours(elm);

    }
  };



  return (
    <>
      <Menu as="div" className="relative inline-block text-left w-full">
        <div className="w-full border border-slate-300 rounded-md">
          <div className="w-full flex   gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 overflow-x-auto overflow-y-hidden">
            <div className="flex w-[98%] overflow-x-auto">
              {bundleTours?.map((elm: any) => (
                <>
                  <div className="flex mr-2 overflow-x-hidden overflow-y-auto">
                    <div className="flex items-center px-2 py-0  border border-lightgray rounded-lg">
                    <p className="text-sm flex items-center  w-fit  ">
                      {elm?.tourBundelsDetails?.name ?? elm?.name}
                     
                    </p>
                    <span>
                    <img
                        src={Images.closecircle}
                        alt=""
                        onClick={() => {
                          handleFilterBundleTours(elm);
                        }}
                        className="w-4 h-4 ml-2"
                      />
                    </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="w-[1%] flex items-center justify-center">
            <Menu.Button >
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400 text-right ml-auto flex justify-end "
                aria-hidden="true"
              />
            </Menu.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg focus:outline-none  ">
            {data?.length &&
              data?.map((item: any) => (
                <div className="">
                  <div className="flex items-center items-start my-2">
                    <input
                      id="checkbox-1"
                      aria-describedby="checkbox-1"
                      type="checkbox"
                      onChange={(e) => {
                        handleChange(e, item);
                      }}
                      defaultChecked={tourInBundle[item.id]}
                      className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded "
                    />
                    <label className="text-sm ml-3 font-medium text-gray-900">
                      {item?.name}
                    </label>
                  </div>
                </div>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
