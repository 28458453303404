import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";

const initialState: any = {
  Admins: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: "",
};
const getAdminsSlice = createSlice({
  name: "getAdmins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminsThank.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getAdminsThank.fulfilled, (state: any, action) => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        Admins: action.payload,
      }))
      .addCase(getAdminsThank.rejected, (state: any, action) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      }));
  },
});

export const getAdminsThank = createAsyncThunk(
  "getAdmins/getAdminsThank",
  async (_, { dispatch }) => {
    return await services.getAdmins();
  }
);

export default getAdminsSlice.reducer;
