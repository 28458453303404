import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";
 

const initialState:any={
Topics:[],
isLoading:false,
isError:false,
isSuccess:false,
error:"",

}
const topicListSlice = createSlice({
    name :"topicList",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(topicListThank.pending,(state)=>({
          ...state,
          isLoading:true
        }))
        .addCase(topicListThank.fulfilled,(state:any,action)=>({
            ...state,
            isLoading:false,
            isSuccess:true,
            Topics :action.payload
          }))
          .addCase(topicListThank.rejected,(state:any,action)=>({
            ...state,
            isLoading:false,
            isError:true,
            error:action.payload
            
          }))
    }


})


export const topicListThank = createAsyncThunk("topicList/topicListThank",async (_, { dispatch }) => {
  return await services.getTopicList();
}
)

 export default topicListSlice.reducer