import React, { useEffect, useState } from "react";
import Images from "../../constant/Images";

const SearchComponent = (data: any) => {
  const [searchItem, setSearchItem] = useState<any>(data);
  const [searchValue, setSearchValue] = useState<any>();
  const [showList, setShowList] = useState(false);
  const [searchData, setSearchData] = useState<any>();
  useEffect(() => {
    setSearchItem(data);
  }, [data]);

  const handleChange = (e: any) => {
    let searchList: any = {...searchItem};
    setSearchValue(e.target.value);
    if(e.target.value.trim() === "") {
      data?.getSearchData(searchList)
      return
    }
    if (!e.target.validity.valueMissing) {
      searchList = searchList?.data?.length &&
      searchList.data?.filter((item: any) =>
        item?.name?.toLowerCase().includes(e.target.value?.toLowerCase()) || e.target.value?.toLowerCase().includes(item?.name?.toLowerCase())
      )
      setSearchData(searchList);
    } else {
      data?.getSearchData && data?.getSearchData(searchList);
    }
  };

  useEffect(() => {
    data?.getSearchData && data?.getSearchData(searchData, searchValue);
  }, [searchData]);

  return (
    <>
      <div className="relative mt-6 flex">
        <img
          className="absolute top-[10px] left-[16px] h-5 "
          src={Images.search}
          alt=""
        />
        <input
          type="search"
          className=" w-[538px] h-10 p-4 pl-10 text-sm text-gray-900 border border-aqua rounded-3xl font-roboto font-normal focus:outline-none cursor-pointer"
          placeholder="Search"
          required
          onChange={handleChange}
          value={searchValue}
        ></input>
      </div>
    </>
  );
};

export default SearchComponent;
