import React, { useState } from "react";
import Images from "../../../constant/Images";
import InputComponent from "../../inputComponent/InputComponent";
import SelectComponent from "../../selectComponent/SelectComponent";
import { STORAGE_PATH, addTourinput } from "../../../constant/string";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../store/app/store";
import { toursThank } from "../../../store/features/toursSlice";
import { Toast } from "react-toastify/dist/components";
import RichTextEditor from "../../RichTextEditor";
import LanguageInputComponentText from "../../inputComponent/LanguageInputComponentText";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import { languages } from "../../../constant/Languages";
const TourModalComponent = () => {
  const dispatch: any = useAppDispatch();
  const [File, setFile] = useState<any>({});
  const places = useSelector((state: any) => state.placesList);

  const { adminDetails } = useSelector((state: any) => state.counter);
  const [showModal, setShowModal] = React.useState(false);
  const [language, setLanguage] = useState<any>({});
  const [descriptionLanguage, setDescriptionLanguage] = useState<any>({});
  const [choosenLanguage,setChoosenLanguage] = useState<any>([])

  const handleAddTour = async (payload: any) => {
    const res: any = await services.createTours({
      ...payload,
      sellingPrice: parseFloat(payload?.sellingPrice),
      
    });

    if (res.status == 200) {
      toast.success(res?.data?.message);
      await dispatch(toursThank());
    } else {
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };
  const formik: any = useFormik({
    initialValues: {
      userId: adminDetails?.adminId,
      productId: "",
      placeId: "",
      name: "",
      imageUrl: "",
      thumbnailUrl: "",
      // audioUrl: "",
      videoUrl: "",
      type: "",
      duration: "",
      description: "",
      title: "",
      retailPrice: "",
      sellingPrice: "",
      reviewCount: "",
      carRentalPerPerson: "",
      narrationPoints: "",
      distance: "",
      bokunId: "",
    },
    validationSchema: Yup.object({
      productId: Yup.string()
        .trim()
        .required("This field is required")
        .test("no-spaces", "Name cannot contain spaces", (value: any) => {
          return value && !/\s/.test(value);
        }),

      placeId: Yup.string().trim().required("This field is required"),
      // name: Yup.toString().required("This field is required"),
      imageUrl: Yup.string().trim().required("This field is required"),
      thumbnailUrl: Yup.string().trim().required("This field is required"),
      // audioUrl: Yup.string().trim().required("This field is required"),
      videoUrl: Yup.string().trim().required("This field is required"),
      type: Yup.string().trim().required("This field is required"),
      duration: Yup.string().required("This field is required"),
      // .notOneOf([0], "Zero and negative values not accepted"),
      // duration: Yup.number()
      //   .typeError("Please enter number value only")
      //   .nullable()
      //   .required("This field is required")
      //   .min(1)
      //   .moreThan(0, "Zero and negative values not accepted"),
      // description: Yup.required("This field is required"),
      title: Yup.string().trim().required("This field is required"),
      retailPrice: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(1)
        .moreThan(0, "Zero and negative values not accepted"),
      // sellingPrice: Yup.string()
      //   .typeError("Please enter number value only")
      //   .nullable()
      //   // .required("This field is required")
      //   .min(1),
      // .moreThan(0, "Zero and negative values not accepted"),
      // retailPrice: Yup.number()
      //   .notOneOf([0], "Zero and negative values not accepted")
      //   .required("This field is required"),
      // .typeError("Please enter number value only")
      // .nullable()
      // .required("This field is required")
      // .min(0)
      // .moreThan(-1, "Negative values not accepted"),
      // sellingPrice: Yup.number()
      //   .lessThan((this as any)?.parent?.retailPrice, "Seller price should be greater"),
      sellingPrice: Yup.number()
        .typeError("Please enter a number value only")
        .nullable()
        .moreThan(-1, "Negative values not accepted")
        .min(0)
        .transform((value, originalValue) => {
          console.log("originalValue: ", originalValue);
          return originalValue.trim() === "" ? null : parseFloat(originalValue);
        })
        .lessThan(
          Yup.ref("retailPrice"),
          "Selling price is less than or equal to retail price"
        ),
      // sellingPrice: Yup.number()
      //   .typeError("Please enter number value only")
      //   .nullable()
      //   .notOneOf([0], "Zero and negative values not accepted")
      //   .required("This field is required")
      //   .min(0)
      //   .moreThan(-1, "Negative values not accepted")
      //   .test(
      //     "match",
      //     "Selling Price is must be less than RetailPrice",
      //     function (sellingPrice: any) {
      //       return sellingPrice <= this.parent.retailPrice;
      //     }
      //   ),
      reviewCount: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(1)
        .moreThan(0, "Zero and negative values not accepted"),
      carRentalPerPerson: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(1)
        .moreThan(0, "Zero and negative values not accepted"),
      // narrationPoints: Yup.string()
      //   .trim()
      //   .required("This field is required")
      //   .notOneOf(["0"], "Zero and negative values not accepted"),
      narrationPoints: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(1)
        .moreThan(0, "Zero and negative values not accepted"),
      // distance: Yup.string()
      //   .trim()
      //   .required("This field is required")
      //   .notOneOf(["0"], "Zero and negative values not accepted"),
      distance: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(1)
        .moreThan(0, "Zero and negative values not accepted"),
      bokunId: Yup.number()
        .typeError("Please enter number value only")
    }),
    onSubmit: (values: any) => {
      try{
      
      
      setShowModal(false);
      formik?.handleReset(formik.values);
      handleAddTour({
        ...values,
        // retailPrice: parseInt(values.retailPrice) || "",
        // sellingPrice:
        //   values.sellingPrice <= 0 ||
        //   !values.sellingPrice ||
        //   values.sellingPrice !== ""
        //     ? parseInt(values.sellingPrice)
        //     : parseInt(values.retailPrice),
        name:language,
        description:descriptionLanguage,
        thumbnailUrl: File?.thumbnailUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
      
      });
    }catch(error){
      console.log("error",error)
    }
    },
  });
  const handleChangeOnFile = async (e: any) => {
    if (
      e?.target?.files[0]?.type === "image/jpg" ||
      e?.target?.files[0]?.type === "image/png" ||
      e?.target?.files[0]?.type === "image/jpeg" ||
      e?.target?.files[0]?.type === "image/svg"
    ) {
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    } else if (e.target.accept == "audio/*") {
      formik.values.audioUrl = e.target.value;
      const res = await services.uploadAudio(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors.audioUrl = "";
      } else {
        formik.values.audioUrl = "";
        formik.errors.audioUrl = "Please select correct img";
      }
    } else {
      toast.error("Only user png,jpeg,jpg,svg format");
    }
  };

  const deleteFile = async()=>{
    
    setFile({
      ...File,
      'thumbnailUrl': '',
    });
    formik.setFieldValue('thumbnailUrl', '');
  }
  const deleteImageUri = async()=>{
    
    setFile({
      ...File,
      'imageUrl': '',
    });
    formik.setFieldValue('imageUrl', '');
  }

  return (
    <>
      <button
        className="rounded-3xl bg-dimblack flex  justify-center items-center w-36 h-10 mt-6"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className=" h-3.5 w-3.5 mr-2 font-roboto font-medium text-xl"
          src={Images.add}
          alt=""
        />
        <p className=" text-white">Add Tour</p>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto overflow-x-hidden">
              {/*content*/}

              {/*header*/}

              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Tours</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}

              <form className="px-12 py-9" onSubmit={formik.handleSubmit}>
                <div className="">
                  {addTourinput.map((elm: any) =>
                    elm?.key == "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={
                          elm?.key1 == "placeId"
                            ? places?.data?.data?.data
                            : elm?.option
                        }
                      />
                    ) :elm?.type === "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        deleteFile ={deleteFile}
                        deleteImageUri = {deleteImageUri}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )  : elm?.name === "description" ? (
                      <RichTextEditor
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          setDescriptionLanguage(data);
                        }}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        discription ={elm?.description}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ):  elm?.name === "name" ? (
                      <>
                      <LanguageInputComponentText
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          setLanguage(data);
                        }}
                        handleSelectedLanguages={(data: any) => {
                          setChoosenLanguage((prev:any)=>[...data])
                        }}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                      </>
                    ): (
                      <InputComponent
                        id={elm?.name}
                        handlechange={
                          elm?.type == "file"
                            ? handleChangeOnFile
                            : formik.handleChange
                        }
                        accept={elm?.accept}
                        value={
                          elm?.type == "file"
                            ? null
                            : (formik as any)?.values?.[elm?.name]
                        }
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                  <div>{/* <AddStopsComponent />{" "} */}</div>
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                    onClick={() => {}}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default TourModalComponent;
