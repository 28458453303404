import React, { useEffect, useState } from "react";
import InputComponent from "../inputComponent/InputComponent";
import Images from "../../constant/Images";
import { addReview } from "../../constant/string";
import services from "../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SelectComponent from "../selectComponent/SelectComponent";
import DeleteModalComponent from "../places/modal/DeleteModalComponent";
import ReviewEdit from "../places/modal/ReviewEdit";
const ReviewComponent = () => {
  const [allreviews, setAllreviews] = useState([]);
  const [reviewsData, setReviewsData] = useState({});
  const { Tours, isLoading, isError, isSuccess } = useSelector(
    (state: any) => state.tours
  );
  const phoneRegExp =
    /^((\\+[1-9]{1}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formik: any = useFormik({
    initialValues: {
      reviewTitle: "",
      rating: "",
      review: "",
      tourId: "",
    },
    validationSchema: Yup.object({
      reviewTitle: Yup.string().trim().required("This field is required"),
      rating: Yup.string()
        .trim()
        .required("This field is required")
        .matches(/^\d+$/, "The field should have digits only"),
      review: Yup.string().trim().required("This field is required"),
      tourId: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
  
      handleAddReviews(values);
    },
  });

  const getdata = async () => {
    try {
      const res = await services.allReviews();
    
      setAllreviews(res?.data?.data);
    } catch (err) {
  
    }
  };

  useEffect(() => {
    if (!allreviews.length) {
      getdata();
    }
  }, []);
  const handleAddReviews = async (payload: any) => {
    const res = await services.postAddReviews(payload);
   
    if (res.status == 200) {
     
      toast.success(res?.data?.message);
      formik.handleReset(formik?.values);
      // formik.values.rating = "";
      getdata();
    } else {
      toast.dismiss();
      toast.error("SomeThing Went Wrong");
    }
  };
  const handleDeleteReview = async (id: number) => {
    const res: any = await services.deleteReviews(id);

    if (res.status == 200) {
      toast.dismiss();
      toast.success(res?.data?.message);
      getdata();
    } else {
      toast.dismiss();
      toast.error("SomeThing Went Wrong");
    }
  };
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full border border-aqua rounded-xl px-12 py-11"
      >
        <p className="font-bold font-sans text-xl">Reviews</p>
        <div className="w-full  mx-auto mt-8">
          {addReview.map((elm: any) =>
            elm?.key == "option" ? (
              <SelectComponent
                landing={true}
                id={elm?.name}
                handlechange={formik.handleChange}
                value={(formik as any)?.values?.[elm?.name]}
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
                option={elm?.key1 == "tourId" ? Tours?.data?.data : elm?.option}
              />
            ) : (
              <InputComponent
                id={elm?.name}
                handlechange={formik.handleChange}
                accept={elm?.accept}
                value={(formik as any)?.values?.[elm?.name]}
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
              />
            )
          )}
        </div>
        <div className="flex justify-between mt-6">
          <div>
            <p className="font-roboto text-xl text-[#616161]">Rating</p>
          </div>
          {/* <div className="flex ">
            {[1, 2, 3, 4].map((elm) => (
              <img src={Images.yStar} alt="" />
            ))}
            <img src={Images.star} alt="" />
          </div> */}
        </div>
        <button
          className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10"
          type="submit"
          onClick={() => {
            
          }}
        >
          Add
        </button>
      </form>
      <div className="w-full h-full border border-aqua rounded-xl mt-9 px-12 py-11">
        <p className="font-bold font-sans text-xl  ">Uploaded Reviews</p>
        {allreviews?.map((eml: any) => (
          <div className=" w-full h-full  border border-[#74A89F70] mx-auto rounded-md mt-6 px-10  py-6 'font-roboto text-xl text-[#616161]'">
            <div className="flex justify-between ">
              <div className="flex justify-between w-full">
                <div className="flex">
                  {eml.reviewTitle}
                  <div className="ml-14 flex">
                    {[1, 2, 3, 4, 5]
                      .filter((item, index) => index < eml?.rating)
                      .map((elm) => (
                        <img src={Images.yStar} alt="" className="mr-4" />
                      ))}
                    {[5, 4, 3, 2, 1]
                      ?.filter((item, index) => index >= eml?.rating)
                      .map((item) => (
                        <img src={Images.star} alt="" className="mr-4" />
                      ))}
                  </div>
                </div>
                <button className="flex">
                  <ReviewEdit data={eml} getdata={getdata}/>
                  <DeleteModalComponent
                    dlFn={() => {
                      handleDeleteReview(eml?.id);
                    }}
                  />
         
                </button>
              </div>
            </div>
            <p className="">{eml.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewComponent;
