import React, { useEffect } from "react";
import PageComponent from "../../component/places/page/PageComponent";
import ArticleComponent from "../../component/article/ArticleComponent";
import ReviewComponent from "../../component/reviews/ReviewComponent";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/app/store";
import VideosComponent from "../../component/videos/VideosComponent";

const Pages = () => {
  const dispatch: any = useAppDispatch();
  const [openTab, setOpenTab] = React.useState(1);
  const color = "black";
  useEffect(() => {}, []);
  const { adminPermission } = useSelector((state: any) => state.counter);

  if (adminPermission?.is_admin == 2 && !adminPermission?.pages) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        {" "}
        You don't have permission
      </h1>
    );
  }
  return (
    <div className="px-12 py-12">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="border-b border-aqua w-full flex">
            <div className="w-2/4">
              <ul
                className="flex mb-0 list-none flex-wrap flex-row "
                role="tablist"
              >
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 1
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    FAQ’s
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 2
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Article
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 3
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Reviews
                  </a>
                </li>

                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 4
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(4);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Video
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="w-2/4 flex justify-end">
              <select
                id="countries"
                className="h-9 w-24 border border-aqua rounded-md outline-none"
              >
                <option selected>Tours</option>
              </select>
            </div> */}
          </div>

          <div className="">
            <div className="mt-9 flex-auto">
              <div className="tab-content tab-space mt-2">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <PageComponent />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <ArticleComponent />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <ReviewComponent />
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <VideosComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
// export default function Pages() {
//     return (
//       <>
//         return <Pages color="slate" />;
//       </>
//     );
//   }
