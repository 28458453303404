import Layout from "../comman/Layout";
import Dashboard from "../screen/dashboard/Dashboard";
import Admin from "../screen/admin/Admin";
import Bundles from "../screen/bundles/Bundles";
import Users from "../screen/users/Users";
import Bookings from "../screen/bookings/Bookings";
import Places from "../screen/places/Places";
import Tours from "../screen/tours/Tours";
import Pages from "../screen/pages/Pages";
import Setting from "../screen/setting/Setting";
import Login from "../screen/login/Login";
import StopList from "../screen/stopList/StopList";
import StopDetails from "../screen/stopDetails/StopDetails";
import AddSaftyInformation from "../screen/addSaftyInformation/AddSaftyInformation";
import ForgotPassword from "../screen/forgotPassword/ForgotPassword";
import OtpValidation from "../screen/otpValidation/OtpValidation";
// import RestPassword from "../screen/restPassword/RestPassword";
import ResetPassword from "../screen/resetPassword/ResetPassword";
const LayoutLanding = Layout(Dashboard);
const LayoutUser = Layout(Users);
const LayoutPlaces = Layout(Places);
const LayoutBundles = Layout(Bundles);
const LayoutTours = Layout(Tours);
const LayoutAdmin = Layout(Admin);
const LayoutPages = Layout(Pages);
const LayoutSetting = Layout(Setting);
const LayoutStopList = Layout(StopList);
const LayoutStopDetails = Layout(StopDetails);
const LayoutSaftyInformation = Layout(AddSaftyInformation);
const LayoutBookings = Layout(Bookings);
const LayoutOtpValidation = Layout(OtpValidation);

// const LayoutLogin = Layout(Login);

export const RouteList = [
  // {
  //   path: "/",
  //   component: <Login />,
  //   role: [101],
  // },
  {
    path: "/",
    component: <Login />,
    role: [201],
  },
  {
    path: "/forgotpassword",
    component: <ForgotPassword/>,
    role: [201],
  },
  {
    path:"/otpverification",
    component:<OtpValidation/>,
    role: [203],
  },
  {
    path:"/resetverification",
    component:<ResetPassword/>,
    role: [204]
  },
  {
    path: "/dashboard",
    component: <LayoutLanding />,
    role: [101],
  },
  {
    path: "/users",
    component: <LayoutUser />,
    role: [102],
  },
  {
    path: "/places",
    component: <LayoutPlaces />,
    role: [103],
  },
  {
    path: "/bundles",
    component: <LayoutBundles />,
    role: [104],
  },
  {
    path: "/tours",
    component: <LayoutTours />,
    role: [105],
  },
  {
    path: "/admin",
    component: <LayoutAdmin />,
    role: [106],
  },
  {
    path: "/pages",
    component: <LayoutPages />,
    role: [107],
  },
  {
    path: "/setting",
    component: <LayoutSetting />,
    role: [108],
  },
  {
    path: "/stopList",
    component: <LayoutStopList />,
    role: [109],
  },
  {
    path: "/stopDetails",
    component: <LayoutStopDetails />,
    role: [109],
  },
  {
    path: "/addSaftyInformation",
    component: <LayoutSaftyInformation/>,
    role: [110],
  },
  {
    path: "/bookings",
    component: <LayoutBookings/>,
    role: [111],
  },
];

export const AutoRouteList = [
  {
    path: "/",
    component: <Login />,
    role: [201],
  },
  {
    path: "/forgotpassword",
    component: <ForgotPassword/>,
    role: [201],
  },
  {
    path:"/otpverification",
    component:<OtpValidation/>,
    role: [203],
  },
  {
    path:"/resetverification",
    component:<ResetPassword/>,
    role: [204]
  },
  // {
  //   path: "/forgotpassword",
  //   // element: <ForgotPasssword />,
  // },
  // {
  //   path: "/resetpassword",
  //   // element: <ResetPassword />,
  // },
  // {
  //   path: "/otpverification",
  //   // element: <OtpVerification />,
  // },
];
