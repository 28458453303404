import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";
 

const initialState:any={
Bundles:[],
isLoading:false,
isError:false,
isSuccess:false,
error:""
}
const getBundlesSlice = createSlice({
    name :"getBundles",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getallBundlesThank.pending,(state)=>({
          ...state,
          isLoading:true
        }))
        .addCase(getallBundlesThank.fulfilled,(state:any,action)=>({
            ...state,
            isLoading:false,
            isSuccess:true,
            Bundles:action.payload
          }))
          .addCase(getallBundlesThank.rejected,(state:any,action)=>({
            ...state,
            isLoading:false,
            isError:true,
            error:action.payload
            
          }))
    }


})


export const getallBundlesThank = createAsyncThunk("getBundles/getallBundlesThank",async (_, { dispatch }) => {
  return await services.getBundles();
}
)

 export default getBundlesSlice.reducer