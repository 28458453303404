import React, { useState } from "react";
import Images from "../../../constant/Images";
import { STORAGE_PATH, addPlaceinput, addTopic } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import services from "../../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../store/app/hooks";
import { topicListThank } from "../../../store/features/topicListSlice";

const AddTopic = ({ setArticData }: any) => {
  const [File, setFile] = useState<any>({});
  const dispatch: any = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [placeData, setPlaceData] = React.useState<any>({});

  const handleAddPlaces = async (payload: any) => {
    const res = await services.createTopics(payload);

    if (res.status == 200) {
      formik.handleReset(payload);
      toast.success(res?.data.message);
      dispatch(topicListThank);
      setArticData((prev: boolean) => !prev);
      setShowModal(false);
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };
  const formik: any = useFormik({
    initialValues: {
      name: "",
      imageUrl: "",
      status: 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("This field is required"),
      imageUrl: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: (values) => {
      handleAddPlaces({
        ...values,
        imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
      });
    },
  });
  const handleChangeOnFile = async (e: any) => {
  
    if (e.target.accept == "image/*") {
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);
     
      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    }
  };
  return (
    <>
      <button
        className="rounded-3xl bg-dimblack flex  justify-center items-center w-36 h-10 "
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className="h-3.5 w-3.5 mr-2 font-roboto font-medium text-xl"
          src={Images.add}
          alt=""
        />
        <p className="text-white">Add Topics</p>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto">
              {/*content*/}

              {/*header*/}
              <div className="text-xl font-semibold bg-lightaqua w-[900px] h-[70px] flex justify-center items-center rounded">
                <h3 className="font-sans font-bold">Add Topics</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center relative left-[23rem]">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none"
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}

              <form className="px-12 py-9" onSubmit={formik.handleSubmit}>
                <div className="">
                  {addTopic.map((elm: any) => (
                    <InputComponent
                      id={elm?.name}
                      handlechange={
                        elm?.type == "file"
                          ? handleChangeOnFile
                          : formik.handleChange
                      }
                      accept={elm?.accept}
                      value={
                        elm?.type == "file"
                          ? null
                          : (formik as any)?.values?.[elm?.name]
                      }
                      name={elm?.name}
                      label={elm?.label}
                      placeholder={elm?.placeHolder}
                      type={elm?.type}
                      error={(formik as any).errors[elm.name]}
                      touched={(formik as any).touched[elm.name]}
                    />
                  ))}
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddTopic;
