import { useEffect, useState } from "react";
import InputComponent from "../../inputComponent/InputComponent";
import { addFaq } from "../../../constant/string";
import services from "../../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SelectComponent from "../../selectComponent/SelectComponent";
import DeleteModalComponent from "../modal/DeleteModalComponent";
import FaqEdit from "../modal/FaqEdit";
const PageComponent = () => {
  const [File, setFile] = useState<any>({});
  const [allFaq, setAllFaq] = useState([]);
  const [faqData, setFaqData] = useState({});
  const { Tours, isLoading, isError, isSuccess } = useSelector(
    (state: any) => state.tours
  );
  const handlechange = (e: any) => {
    setFaqData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const getdata = async () => {
    try {
      const res = await services.getAllFaq();
      setAllFaq(res?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getdata();
  }, []);
  const handleAddFaqs = async (values: any) => {
    const res = await services.postAddFaq(values);
    if (res.status == 200) {
      formik.handleReset(values);
      getdata();
      toast.success(res?.data?.message);
    } else {
      toast.dismiss();
      toast.error("someThing Went Wrong");
    }
  };
  const handleRemoveFaq = async (id: number) => {
    const res: any = await services.removeAddFaq(id);
    if (res.status === 200) {
      toast.success(res?.data.message);
      getdata();
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };
  // formik intrigation

  const formik = useFormik({
    initialValues: {
      filterName: "",
      addQuestion: "",
      addAnswer: "",
      tourId: "",
    },
    validationSchema: Yup.object({
      tourId: Yup.string().trim().required("This field is required"),
      filterName: Yup.string().trim().required("This field is required"),
      addQuestion: Yup.string().trim().required("This field is required"),
      addAnswer: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: (values) => {
      handleAddFaqs(values);
    },
  });

  return (
    <div>
      <form
        onSubmit={formik?.handleSubmit}
        className="w-full  border border-aqua rounded-xl px-12 py-11"
      >
        <p className="font-bold font-openSans text-xl">FAQ's</p>
        <div className="w-full  mx-auto mt-8">
          {addFaq.map((elm: any) =>
            elm?.key == "option" ? (
              <SelectComponent
                id={elm?.name}
                landing={true}
                handlechange={formik.handleChange}
                value={(formik as any)?.values?.[elm?.name]}
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                key={elm?.key1}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
                option={elm?.key1 == "tourId" ? Tours?.data?.data : elm?.option}
              />
            ) : (
              <InputComponent
                id={elm?.name}
                handlechange={formik.handleChange}
                accept={elm?.accept}
                value={(formik as any)?.values?.[elm?.name]}
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
              />
            )
          )}
        </div>
        <button
          className="w-[200px] h-[50px] bg-aqua rounded  text-brown mt-10"
          type="submit"
        >
          Add
        </button>
      </form>
      <div className="w-full h-full border border-aqua rounded-xl  px-12 py-11 mt-2">
        <p className="font-bold font-openSans text-xl ">FAQ’s Added</p>
        {allFaq.map((eml: any) => (
          <div className=" w-full   border border-[#74A89F70]  rounded-md mt-6 px-10 py-6 'font-roboto text-xl text-[#616161]'">
            {eml?.tourId == 0 ? (
              <h4 className="text-xl font-openSans font-bold text-black">
                For Landing
              </h4>
            ) : null}
            <div className="flex justify-between mt-4">
              <p className="text-xxl font-openSans font-bold text-black capitalize">
                {eml?.name}
              </p>
              <button className="flex">
                <FaqEdit getdata={getdata} data={eml}  />
                <DeleteModalComponent
                  dlFn={() => {
                    handleRemoveFaq(eml?.id);
                  }}
                />
                {/* <img
                  src={Images.admincrose}
                  alt=""
                  // onClick={() => handleRemoveFaq(eml?.id)}
                /> */}
              </button>
            </div>
            <p className="text-xxl text-[#616161] font-roboto font-normal mt-4">
              {eml?.topQuestion}
            </p>
            <p className="text-xl text-[#616161] font-roboto font-normal mt-2">
              {eml.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageComponent;
