import React from "react";

const BookingsTableComponent = ({ allUser }: any) => {

  return (
    <>
      <table className="w-full text-sm text-left  h-40 mb-9">
        <thead className="text-xs font-Roboto font-normal text-lightgray ">
          <tr>
            <th scope="col" className="p-4">
              <div className="flex items-center"></div>
            </th>
            <th scope="col" className="" style={{ width: '8%' }}>
              BookingId
            </th>
            <th scope="col" className="" style={{ width: '8%' }}>
              Name
            </th>
            <th scope="col" className="" style={{ width: '25%' }}>
              Email
            </th>
            <th scope="col" className="" >
              Phone No.
            </th>
            <th scope="col" className="">
              Tour
            </th>
            <th scope="col" className="">
              Redeem Code
            </th>
            <th scope="col" className="">
              Redeems Left
            </th>
          </tr>
        </thead>
        <tbody>
          {allUser &&
            allUser?.map((elm: any, index: any) => (
              <tr
                key={index}
                className="bg-white border-b  border-aqua  "
              >
                <td className="w-4 p-4">
                  <div className="flex items-center"></div>
                </td>
                <td className="">{elm.bookingId}</td>
                <td className="">{elm.name}</td>
                <td className="">{elm?.email}</td>
                <td className="">{elm?.phoneNumber}</td>
                <td className="">{elm?.productTitle}</td>
                <td className="">{elm?.redeemCodeData?.redeemCode}</td>
                <td className="">{elm?.redeemCodeData?.redeemCounter}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default BookingsTableComponent;
