import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";
const initialState:any={
Articles:[],
isLoading:false,
isError:false,
isSuccess:false,
error:"",
}
const articlesListSlice = createSlice({
    name :"articlesList",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(articlesListThank.pending,(state)=>({
          ...state,
          isLoading:true
        }))
        .addCase(articlesListThank.fulfilled,(state:any,action)=>({
            ...state,
            isLoading:false,
            isSuccess:true,
            Articles :action.payload
          }))
          .addCase(articlesListThank.rejected,(state:any,action)=>({
            ...state,
            isLoading:false,
            isError:true,
            error:action.payload
            
          }))
    }


})


export const articlesListThank = createAsyncThunk("articlesList/articlesListThank",async (_, { dispatch }) => {
  return await services.articlesList();
}
)

 export default articlesListSlice.reducer