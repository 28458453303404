import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from '../app/store'

// Define a type for the slice state
interface CounterState {
  value: number;
  adminDetails: any;
  forgetPassword:any
  adminPermission:any
}

// Define the initial state using that type
const initialState: CounterState = {
  value: 0,
  adminDetails: {},
  forgetPassword:{},
  adminPermission:{}
};

export const counterSlice = createSlice({
  name: "counter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateAdmin : (state: CounterState, action: any) => {
      state.adminDetails = action.payload;
    },
    setForgetPassword:(state: CounterState, action: any) => {
      state.forgetPassword = action.payload;
    },
    setadminPermission:(state: CounterState, action: any) => {
      state.adminPermission = action.payload;
    }
  },
});

export const { updateAdmin , setForgetPassword,setadminPermission} = counterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: any) => state.counter.value;

export default counterSlice.reducer;
