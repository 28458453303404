import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "../../constant/Images";
import { StoreCookie } from "../../Auth/sessionStore";
import services from "../../services/services";
import { toast } from "react-toastify";
import { getAdminsThank } from "../../store/features/getAdminsSlice";
import { useSelector } from "react-redux";
import storage from 'redux-persist/lib/storage';
import { useAppDispatch } from "../../store/app/store";
import {  updateAdmin } from "../../store/features/counterSlice";
import sessionStorage from "redux-persist/es/storage/session";


const SidebarComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const adminData = useSelector((state:any)=>state.counter)
  const [path, setPath] = useState("/dashbord");
  const location = useLocation();

  useEffect(() => {
    setPath(location?.pathname);
    // handleAddAdminEmail()
  }, [location]);

  return (
    <aside
      id="default-sidebar"
      className="fixed top-0 left-0 z-40 w-1/5 h-screen transition-transform -translate-x-full sm:translate-x-0 "
      aria-label="Sidebar"
    >
      <div className="h-full  py-4 overflow-y-auto bg-[#44C7B340] ">
        <div className="border-b-[2px] border-aqua">
          <img
            className="h-20 w-24 mx-auto my-6 "
            src={Images.logo}
            alt=""
          ></img>
        </div>

        <ul className="space-y-2 mt-9">
          <Link
            to="/dashboard"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/dashboard" ? "bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-4" src={Images.dashboard} alt="" />
            <span className="text-2xl font-roboto font-normal text-blue">
              Dashboard
            </span>
          </Link>
          <Link
            to="/users"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/users" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-3" src={Images.users} alt="" />
            <span className=" text-2xl font-roboto font-normal text-blue">
              Users
            </span>
          </Link>
          <Link
            to="/places"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/places" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-4" src={Images.places} alt="" />
            <span className="text-2xl font-roboto font-normal text-blue">
              Places
            </span>
          </Link>
          <Link
            to="/tours"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/tours" ||
              path === "/stopList" ||
              path === "/stopDetails" ||
              path === "/addSaftyInformation"
                ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5"
                : ""
            }`}
          >
            <img className="ml-6 mr-2" src={Images.tours} alt="" />
            <span className="text-2xl font-roboto font-normal text-blue  ">
              Tours
            </span>
          </Link>
          <Link
            to="/bundles"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/bundles" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-3" src={Images.bundles} alt="" />
            <span className=" text-2xl font-roboto font-normal text-blue ">
              Bundles
            </span>
          </Link>
          <Link
            to="/bookings"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/bookings" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-4" src={Images.bookings} alt="" />
            <span className="text-2xl font-roboto font-normal text-blue">
              Bookings
            </span>
          </Link>
          <Link
            to="/admin"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/admin" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-3" src={Images.admin} alt="" />
            <span className=" text-2xl font-roboto font-normal text-blue ">
              Admin
            </span>
          </Link>
          <Link
            to="/pages"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/pages" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-3" src={Images.page} alt="" />
            <span className=" text-2xl font-roboto font-normal text-blue ">
              Pages
            </span>
          </Link>
          <Link
            to="/setting"
            className={`px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black   ${
              path === "/setting" ? " mt-9 bg-[#44C7B3] rounded-3xl mx-5" : ""
            }`}
          >
            <img className="ml-6 mr-3" src={Images.settings} alt="" />
            <span className="text-2xl font-roboto font-normal text-blue  ">
              Settings
            </span>
          </Link>
          <span
            // to="/"
            onClick={() => {
              localStorage.clear();
              // sessionStorage.clear();
              sessionStorage.removeItem('persist:root')
              // dispatch(updateAdmin({name:",abmnabc"}));
              storage.removeItem("userToken");
              navigate("/");
              window.location.reload();
            }}
            className="px-3 py-2 flex items-center text-xxl  font-bold leading-snug text-black"
          >
            <img className="ml-6 mr-3" src={Images.logout} alt="" />
            <span className=" text-2xl font-roboto font-normal text-blue ">
              Logout
            </span>
          </span>
        </ul>
      </div>
    </aside>
  );
};

export default SidebarComponent;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

