import React from "react";

const SelectComponent = ({
  label,
  value,
  placeholder,
  type,
  option,
  name,
  handlechange,
  touched,
  error,
  landing,
}: any) => {
  return (
    <div>
      <label className="flex mb-2 mt-3 text-xl font-bold text-darkgray font-roboto  justify-start">
        {label}
      </label>
      <select
        onChange={handlechange}
        id="default"
        name={name}
        value={value}
        className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 outline-none"
      >
        <option value={""}>Please Select Option</option>
        {landing ? <option value={0}>for Landing </option> : null}
        {option?.length &&
          option?.map((elm: any) => <option key={elm.id} value={elm.id}>{elm.name}</option>)}
      </select>
      {error && touched && <p className="text-red-600">{error}</p>}
    </div>
  );
};

export default SelectComponent;
