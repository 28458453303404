import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AutoRouteList, RouteList } from "./routes/Routes";
import "react-toastify/dist/ReactToastify.css";
import Login from "./screen/login/Login";
import { ToastContainer } from "react-toastify";
import { StoreCookie } from "./Auth/sessionStore";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toursThank } from "./store/features/toursSlice";
import { useAppDispatch } from "./store/app/store";
import { PlacesList } from "./store/features/placesListSlice";
import { getallBundlesThank } from "./store/features/getBundlesSlice";
function App() {
  const counter = useSelector((state: any) => state.counter);
  const isAuthenticate = counter?.adminDetails?.uid;

  const dispatch: any = useAppDispatch();
  const navigator = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(toursThank());
    dispatch(PlacesList());
    dispatch(getallBundlesThank());
  }, []);
  // useEffect(()=>{
  //   if(!isAuthenticated){
  //     localStorage.setItem("authToken", JSON.stringify(isAuthenticate));
  //   }
  // },[isAuthenticated])
  // useEffect(() => {
  //   const protectedRoutes = [
  //     "/forgotpassword",
  //     "/resetverification",
  //     "/otpverification",
  //   ];
  const pureRoutes = [
    "/",
    "/forgotpassword",
    "/resetverification",
    "/otpverification",
    "/forgotpassword",
    "/resetverification",
    "/otpverification",
    "/dashboard",
    "/users",
    "/places",
    "/bundles",
    "/tours",
    "/bookings",
    "/admin",
    "/pages",
    "/setting",
    "/stopList",
    "/stopDetails",
    "/addSaftyInformation",
  ];
  const redirectPath = isAuthenticate ? location.pathname : "/";
  //   if (!isAuthenticate && protectedRoutes.includes(location.pathname)) {
  //     navigator(location);
  //   } else
  useEffect(() => {
    if (!pureRoutes.find((item) => item === location.pathname)) {
      navigator(-1);
    }
  }, []);
  //  else {
  //   navigator(redirectPath);
  // }
  // }, [isAuthenticate, location.pathname]);
  // useEffect(() => {
  //   isAuthenticate && setUpInterceptor(isAuthenticate);
  // }, [isAuthenticate]);
  return (
    <>
      {!isAuthenticate ? (
        <Routes>
          {AutoRouteList.map((elm: any, idx: number) => (
            <Route key={idx} path={elm.path} element={elm.component} />
          ))}
        </Routes>
      ) : (
        <Routes>
          {RouteList.map((elm: any, idx: number) => (
            <Route key={idx} path={elm.path} element={elm.component} />
          ))}
        </Routes>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default App;
