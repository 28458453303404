import React, { useState } from "react";
import Images from "../../constant/Images";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import services from "../../services/services";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/app/store";
import { updateAdmin } from "../../store/features/counterSlice";
import setUpInterceptor from "../../services/Intercepter";
import { StoreCookie } from "../../Auth/sessionStore";
const OtpValidation = () => {
  const admin = useSelector((state:any)=>state.counter);
 
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const [otp, setOtp] = useState("");
  const handleLogin = async (payload: any) => {
    const res: any = await services.getOtp(payload);
    if ((res as any)?.status == 200) {
   
      localStorage.setItem("token" ,(res as any)?.data?.sessionData?.uid)
 
      
      setUpInterceptor((res as any)?.data?.sessionData?.uid);
      StoreCookie.setItem("userToken", (res as any)?.data?.sessionData?.uid); 
      dispatch(updateAdmin(res?.data?.sessionData));
      toast.success(res?.data?.message);
      navigate("/resetverification");
    } else {
    
      res
        ? toast.error(res.response.data.message)
        : res?.response?.data?.message
        ? toast.error(res?.response?.data?.message)
        : toast.error("something went wrong");
      // setTimeout(() => {
      //   setIsLogIn(false);
      // }, 5000);
    }
  };
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-1/3 m-auto ">
        <img src={Images.logo} alt="" />
        <h3 className="font-bold font-ProximaNovaAltBold text-7xl xl:text-6xl mt-11">
         Verification
        </h3>
        <div className="mt-8">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            //   renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
            //   type={number}
                {...props}
                className="border border-aqua rounded-md !w-11 !h-11 mr-3 focus:border-aqua focus:outline-none"
              />
            )}
          />
        </div>
        {/* <Link to="/resetverification" className="w-full"> */}
        <button onClick={()=>{
            if (otp) {
              const payload ={
                id:admin?.forgetPassword?.id,
                otp:otp

              }
                   handleLogin(payload)
            }
        }} className="bg-aqualight text-white mt-16  py-2 rounded-md w-full text-xl focus:border-transparent focus:outline-none" type="button">Verify</button>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default OtpValidation;
