import React from "react";
import { useSelector } from "react-redux";
import { BaseUrl, ImgUrl } from "../../services/InstanceAxios";
import { STORAGE_PATH } from "../../constant/string";
import { languages } from "../../constant/Languages";
import { getStringOrParsedJson } from "../../services/Tour";

const StopDetails = () => {
  const { StopDetails, tour } = useSelector(
    (state: any) => state.singleTourSlice
  );
  const [tourLangs, setTourLangs] = React.useState<any[]>(
    getStringOrParsedJson(StopDetails?.stopLanguagesMap) || []
  );
  const [previewLangs, setPreviewLangs] = React.useState<any[]>(
    getStringOrParsedJson(StopDetails?.stopLanguagesPreviewMap) || []
  );
  const [previewTourName, setTourName] = React.useState<any[]>(
    getStringOrParsedJson(tour?.name) || []
  );
  const [previewStopName, setStopName] = React.useState<any[]>(
    getStringOrParsedJson(StopDetails?.name) || []
  );
  const [previewDescription, setDescription] = React.useState<any[]>(
    getStringOrParsedJson(StopDetails?.discription) || []
  );
  const tourLangsAudioRef = React.useRef<any>([]);
  const previewLangsAudioRef = React.useRef<any>([]);

  const handlePlayTourLangs = (id: any) => {
    tourLangsAudioRef?.current?.map((audio: any, index: number) => {
      if (id !== index) {
        audio.pause();
      }
    });
  };

  const handlePlayPreviewLangs = (id: any) => {
    previewLangsAudioRef?.current?.map((audio: any, index: number) => {
      if (id !== index) {
        audio.pause();
      }
    });
  };

  return (
    <div className="px-12 py-6">
      <div className="flex justify-between mt-4">
        <div>
          <div>
            <h5 className="text-blue text-xl font-roboto font-bold ">
              Tour Name
            </h5>

            {previewTourName?.map((item: any, index: number) => (
              <div className="flex justify-between mt-4">
                <div className="flex align-items-center">
                  <img
                    style={{ width: 30, height: 20 }}
                    src={
                      languages.find((elm) => elm.code === item?.language)
                        ?.flagUrl
                    }
                    alt="flag"
                  />
                  <p className="text-blue font-roboto font-bold mx-4">
                    {languages.find((elm) => elm.code === item?.language)?.name}
                  </p>

                  <p className="text-blue font-roboto font-bold mx-4">
                    {item?.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <h5 className="text-blue text-xl font-roboto font-bold ">
              Stop Name
            </h5>

            {previewStopName?.map((item: any, index: number) => (
              <div className="flex justify-between mt-4">
                <div className="flex align-items-center">
                  <img
                    style={{ width: 30, height: 20 }}
                    src={
                      languages.find((elm) => elm.code === item?.language)
                        ?.flagUrl
                    }
                    alt="flag"
                  />
                  <p className="text-blue font-roboto font-bold mx-4">
                    {languages.find((elm) => elm.code === item?.language)?.name}
                  </p>

                  <p className="text-blue font-roboto font-bold mx-4">
                    {item?.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <h5 className="text-blue text-xl font-roboto font-bold ">
              Description
            </h5>

            {previewDescription?.map((item: any, index: number) => (
              <div className="flex justify-between mt-4">
                <div className="flex align-items-center">
                  <img
                    style={{ width: 30, height: 20 }}
                    src={
                      languages.find((elm) => elm.code === item?.language)
                        ?.flagUrl
                    }
                    alt="flag"
                  />
                  <p className="text-blue font-roboto font-bold mx-4">
                    {languages.find((elm) => elm.code === item?.language)?.name}
                  </p>

                  <p className="text-blue font-roboto font-bold mx-4">
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.text }}
                    />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <img
            className="rounded-2xl mr-4 border border-dark"
            src={
              StopDetails?.thumbnailUrl
                ? ImgUrl +
                  StopDetails?.thumbnailUrl.replace(
                    /\/root\/imageUpload\//g,
                    ""
                  )
                : ""
            }
            alt={StopDetails?.name}
          />
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <div>
          <p className="text-blue text-xl font-roboto font-bold ">
            Longitude : {StopDetails?.longitude}
          </p>
        </div>
        <div>
          <p className="text-blue text-xl font-roboto font-bold ">
            Lattitude : {StopDetails?.lattitude}
          </p>
        </div>
        <div>
          <p className="text-blue text-xl font-roboto font-bold ">
            Radius : {StopDetails?.radius}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <hr />
      </div>
      <div className="mt-4">
        <h5 className="text-blue text-xl font-roboto font-bold ">
          Preview Audios
        </h5>
        <div>
          {previewLangs?.map((item: any, index: number) => (
            <div className="flex justify-between mt-4">
              <div className="flex align-items-center">
                <img
                  style={{ width: 30, height: 20 }}
                  src={
                    languages.find((elm) => elm.code === item?.language)
                      ?.flagUrl
                  }
                  alt="flag"
                />
                <p className="text-blue font-roboto font-bold mx-4">
                  {languages.find((elm) => elm.code === item?.language)?.name}
                </p>

                <audio
                  onPlay={() => {
                    handlePlayPreviewLangs(index);
                  }}
                  ref={(elm) => (previewLangsAudioRef.current[index] = elm)}
                  controls
                  src={
                    BaseUrl +
                    "/audioUpload/" +
                    item?.file?.replace(`${STORAGE_PATH}/audioUpload/`, "")
                  }
                  style={{ height: 25 }}
                ></audio>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <hr />
      </div>
      <div className="mt-4">
        <h5 className="text-blue text-xl font-roboto font-bold ">
          Language Audios
        </h5>
        <div>
          {tourLangs?.map((item: any, index: number) => (
            <div className="flex justify-between mt-4">
              <div className="flex align-items-center">
                <img
                  style={{ width: 30, height: 20 }}
                  src={
                    languages.find((elm) => elm.code === item?.language)
                      ?.flagUrl
                  }
                  alt="flag"
                />
                <p className="text-blue font-roboto font-bold mx-4">
                  {languages.find((elm) => elm.code === item?.language)?.name}
                </p>

                <audio
                  onPlay={() => {
                    handlePlayTourLangs(index);
                  }}
                  ref={(elm) => (tourLangsAudioRef.current[index] = elm)}
                  controls
                  src={
                    BaseUrl +
                    "/audioUpload/" +
                    item?.file?.replace(`${STORAGE_PATH}/audioUpload/`, "")
                  }
                  style={{ height: 25 }}
                ></audio>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-16"></div>
    </div>
  );
};

export default StopDetails;
