import React from "react";
import Images from "../../constant/Images";
import { cardList } from "../../constant/string";
const CardsComponent = ({ data }: any) => {
  return (
    <>
      <div className="flex justify-between w-full">
        {cardList.map((elm: any) => (
          <div className=" flex justify-center items-center flex-col border-solid border border-aqua w-44 h-28 rounded-lg p-2.5 hover:bg-aqua ">
            <p className="font-sans text-xl font-bold text-dimblack">
              {elm.title}
            </p>
            <h1 className="font-Roboto text-2xl font-medium text-dimblack mb-1.5  ">
              {data && data[elm?.name] ? (+data[elm?.name]).toFixed(2) : 0}
            </h1>
          </div>
        ))}
      </div>
    </>
  );
};

export default CardsComponent;
