import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";

const initialState: any = {
  Tours: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: "",
};
const toursSlice = createSlice({
  name: "tours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(toursThank.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(toursThank.fulfilled, (state: any, action) => ({
        ...state,
        isLoading: false,
        isSuccess: true,
        Tours: action.payload,
      }))
      .addCase(toursThank.rejected, (state: any, action) => ({
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      }));
  },
});

export const toursThank = createAsyncThunk(
  "tours/toursThank",
  async (_, { dispatch }) => {
    return await services.getAllTour();
  }
);

export default toursSlice.reducer;
