import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
import { adminFunctions, userEdit } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import services from "../../../services/services";

import { getAdminsThank } from "../../../store/features/getAdminsSlice";
import { useAppDispatch } from "../../../store/app/store";
import { toast } from "react-toastify";

const AdminPopup = ({ adminDetails, id }: any) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch: any = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);

  const [userInfo, setUserInfo] = React.useState<any>({
    bundels:
      adminDetails && adminDetails?.bundels
        ? adminDetails?.bundels
          ? true
          : false
        : false,
    tours:
      adminDetails && adminDetails?.tours
        ? adminDetails?.tours
          ? true
          : false
        : false,
    editUser:
      adminDetails && adminDetails?.editUser
        ? adminDetails?.editUser
          ? true
          : false
        : false,
    // users:
    //   adminDetails && adminDetails?.users
    //     ? adminDetails?.users
    //       ? true
    //       : false
    //     : false,
        places:
      adminDetails && adminDetails?.places
        ? adminDetails?.places
          ? true
          : false
        : false,
    admin:
      adminDetails && adminDetails?.admin
        ? adminDetails?.admin
          ? true
          : false
        : false,
    pages:
      adminDetails && adminDetails?.pages
        ? adminDetails?.pages
          ? true
          : false
        : false,
  });

  const handlechange = (e: any) => {
  

    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.checked,
    });
  };

  const create_Upadate_AdminPermissions = async (payload: any) => {
   
    let res: any;
    if (!adminDetails) {
      res = await services?.createAdminPermissions(payload, id);
      
    } else {
      res = await services?.updateAdminPermissions(payload, id);
 
    }
    if (res?.status == 200) {
      toast.success(res?.data?.message);
      dispatch(getAdminsThank());
    } else {
      toast.error("something went wrong");
    }
    setIsAdmin(false);
    setShowModal(false);
  };

  return (
    <>
      <img
        className="font-roboto font-medium text-xl  w-6 h-6 cursor-pointer mr-3"
        src={Images.edit}
        alt=""
        onClick={() => setShowModal(true)}
      />

      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none  ">
            <div className="relative w-[900px]  bg-white my-6 rounded  overflow-auto overflow-x-hidden">
              {/*content*/}

              {/*header*/}
              <div className="text-xl  px-2 font-semibold bg-lightaqua w-[900px] h-[50px] flex justify-between items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Admin Functions</h3>
                <div className="bg-aquadark w-[50px] h-[50px] rounded-xl flex justify-center ">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none"
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              <div className="w-full h-full border-0 border-aqua rounded-xl mt-9 py-6 px-11">
                {/* <p className="font-bold font-sans text-xl ">Admin Functions</p> */}
                {adminFunctions.map((elm: any) => (
                  <div className="flex justify-between h-[50px] border border-[#74A89F70] rounded  outline-none p-3 mt-3">
                    <p className="font-xl font-roboto font-normal text-lg text-[#151C2A]">
                      {" "}
                      {elm.name}
                    </p>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name={elm.value}
                        checked={userInfo[elm?.value]}
                        className="sr-only peer checkboxInput"
                        // checked ={}
                        onChange={handlechange}
                      ></input>
                      {/* <div className="w-11 h-6 bg-aqua rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 focus:bg-red-500"></div> */}
                      <div className="w-11 h-6 bg-aqua dark:peer-focus:ring-white rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-aqua after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-aqua after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-aqualight"></div>
                    </label>
                  </div>
                ))}
              </div>
              {/*footer*/}
              <button
                className="w-[150px] h-[50px] border rounded border-aqua ml-11 text-aqua mb-9"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6 mb-9"
                type="button"
                onClick={() => {
                  setIsAdmin(true);
                  
                  create_Upadate_AdminPermissions({
                    bundels: +userInfo.bundels,
                    tours: +userInfo.tours,
                    editUser: +userInfo.editUser,
                    // users:+userInfo?.users,
                    places:+userInfo?.places ,
                    admin:+userInfo?.admin,
                    pages:+userInfo?.pages,
                    // places:1
                  });
                }}
              >
                {isAdmin ? "Processing..." : "Save"}
              </button>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AdminPopup;
