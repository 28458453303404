import { useEffect, useState } from "react";
import services from "../../services/services";
import InputComponent from "../inputComponent/InputComponent";
import AdminPopup from "../places/modal/AdminPopup";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/app/store";
import { getAdminsThank } from "../../store/features/getAdminsSlice";
import { toast } from "react-toastify";
import AdminDeleteUser from "../adminDeleteUser/AdminDeleteUser";
const FormComponent = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const admins = useSelector((state: any) => state.getAdmins);
  const dispatch: any = useAppDispatch();
  const counter = useSelector((state:any)=>state.counter)
  const isAuthenticate = counter?.adminDetails?.uid;
  useEffect(() => {
    if (!admins.Admins?.data?.data?.length) {
      dispatch(getAdminsThank());
    }
  }, []);
  const handleAddAdminEmail = async (payload: any) => {
    console.log("sendhav",isAuthenticate);
    
    setIsAdmin(true);
    const res = await services.inviteAdmin(payload ,isAuthenticate);
    if (res?.status) {
      toast.dismiss();
      toast.success(res?.data.message);
      dispatch(getAdminsThank());
      formik?.handleReset(payload);
    }
    setIsAdmin(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Invalid email address")
        .required(" Required"),
    }),
    onSubmit: (values, error) => {
      if (!isAdmin) {
        handleAddAdminEmail(values);
      }
    },
  });

  // if (admins?.isLoading) {
  //   return (
  //     <div className="w-full h-[100%] flex justify-center items-center">
  //       <h1 className="text-lg text-red-500">loading...</h1>;
  //     </div>
  //   );
  // }

  return (
    <div className="px-5">
      <div className="w-full h-full border border-aqua rounded-xl py-6 px-11">
        <div>
          <p className="font-bold font-sans text-xl">Invite Sub-Admin</p>
          {[1].map((elm) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (formik.errors.email) {
                  toast.dismiss();
                  toast.error("please Enter valid Email ");
                }

                formik.handleSubmit(e);
              }}
              className="w-full flex mt-3 items-center"
            >
              <div className="w-10/12 ">
                <InputComponent
                  name="email"
                  handlechange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeHolder="Enter Email"
                  // error={(formik as any).errors?.email}
                  // touched={(formik as any).touched?.email}
                />
              </div>
              <button
                className="bg-aqua w-2/12 h-[41px] ml-5 rounded mb-[-12px]"
                type="submit"
                // onClick={() => {
                //   handleAddAdminEmail();
                // }}
              >
                {isAdmin ? "Processing..." : "Add"}
              </button>
              {/* <button
                className="w-2/12 bg-aqua rounded ml-5 text-brown "
                onClick={() => {
                  handleAddAdminEmail();
                }}
              >
                Add
              </button> */}
            </form>
          ))}
        </div>

        <div className="relative overflow-x-auto ">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <p className="font-bold font-sans text-xl mt-12 mb-4">
              Current Team Member
            </p>
            <tbody className="">
              {admins.Admins?.data?.data?.map((elm: any) => (
                <tr className="bg-white ">
                  <td className="py-1 font-medium text-lg font-inter flex">
                    <AdminPopup adminDetails={elm?.adminDetails} id={elm?.id}/>{" "}
                    {elm?.email}
                  </td>
                  <td className=" font-medium text-lg font-inter">sub-admin</td>
                  <td>
                  </td>
                  <td className="">
                    <AdminDeleteUser id={elm.id}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="w-full h-full border border-aqua rounded-xl mt-9 py-6 px-11">
        <p className="font-bold font-sans text-xl ">Admin Functions</p>
        {adminFunctions.map((elm: any) => (
          <div className="flex justify-between h-[50px] border border-[#74A89F70] rounded  outline-none p-3 mt-3">
            <p className="font-xl font-roboto font-normal text-lg text-[#151C2A]">
              {" "}
              {elm.name}
            </p>

            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer "></input>
              <div className="w-11 h-6 bg-aqua rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        ))}
      </div> */}
    </div>
    // </div>
  );
};
export default FormComponent;
