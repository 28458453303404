import React, { useEffect, useState } from "react";
import InputComponent from "../../component/inputComponent/InputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import services from "../../services/services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import RichTextEditor from "../../component/RichTextEditor";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './SaftyInfoInput.css'

function SaftyInfoInput({ elm, data, getSaftyInfo }: any) {
  const [isSaftyInfo, setIsSaftyInfo] = useState<any>(true);
  const tours = useSelector((state: any) => state.singleTourSlice);

  const addSaftyInfo = async (payload: any) => {
    const res: any = await services?.addSaftyInfo(payload);
    if (res?.status) {
      getSaftyInfo(data?.tourId);
      toast.success("Successfully create");
      setIsSaftyInfo(true);
    }
  };
  const updateSaftyInfo = async (id: any, payload: any) => {
    const res: any = await services?.updateSaftyInfo(id, payload);

    if (res?.status) {
      getSaftyInfo(data?.tourId);
      toast.success("Successfully Update");
      setIsSaftyInfo(true);
    }
  };

  const formik: any = useFormik({
    initialValues: {
      notes: "",
      description: "",
    },
    validationSchema: Yup.object({
      notes: Yup.string().trim().required("This field is required"),
      description: Yup.string()
        .trim()
        .required("This field is required"),
    }),
    onSubmit: (values) => {},
  });

  const deleteSaftyInfo = async (id: any) => {
    const res: any = await services?.deleteSaftyInfo(id);

    if (res?.status) {
      getSaftyInfo(data?.tourId);
      toast.success("Successfully Delete");
      setIsSaftyInfo(true);
      // formik.values = {};
      formik.handleReset(formik.values);
    }
  };
  return (
    <form onSubmit={formik?.handleSubmit} className="mt-6">
      <h5 className="text-sm font-bold text-black">
        {elm?.type + ". " + elm?.label}
      </h5>
      {data && isSaftyInfo ? (
        <div>
          <h4 className="text-xl font-bold font-openSans text-black mt-3 underline">
            Title
          </h4>
          <p className="text-sm font-openSans text-black ">
            {data?.notes}
          </p>
          <h4 className="text-xl font-bold font-openSans text-black mt-3 underline">
            Description
          </h4>
          <p className="text-sm font-openSans text-black">
          <ReactQuill
                modules={{
                  "toolbar": false
                }}
                 value={data?.description}
                 readOnly={true} />
          </p>
        </div>
      ) : (
        <>
          <InputComponent
            label="Add Title"
            placeholder="Add title"
            id={"notes"}
            handlechange={formik.handleChange}
            value={(formik as any)?.values?.notes}
            name={"notes"}
            type={"text"}
            error={(formik as any).errors?.notes}
            touched={(formik as any).touched?.notes}
          />
          <RichTextEditor
            label="Add Description"
            placeholder="Add description"
            id={"description"}
            handlechange={(data: any) => {
              formik.setFieldValue("description", data, true);
            }}
            value={(formik as any)?.values?.description}
            name={"description"}
            type={"text"}
            error={(formik as any).errors?.description}
            touched={(formik as any).touched?.description}
          />
        </>
      )}
      <div className="mt-9 ">
        {data && isSaftyInfo ? (
          <button
            className="w-[150px] h-[40px] border rounded border-aqua  text-aqua hover:shadow-3xl bg-white hover:bg-aqua  hover:text-white"
            type="button"
            onClick={() => {
              deleteSaftyInfo(data?.id);
            }}
          >
            Delete
          </button>
        ) : (
          <button
            className="w-[150px] h-[40px] border rounded border-aqua  text-aqua hover:shadow-3xl bg-white hover:bg-aqua hover:text-white"
            type="button"
            onClick={() => {
              formik.handleReset(formik.values);
              setIsSaftyInfo(true);
            }}
          >
            Cancel
          </button>
        )}
        {data && isSaftyInfo ? (
          <button
            className="w-[150px] h-[40px] border rounded bg-aqua text-white ml-6 hover:bg-aqualight hover:shadow-3xl"
            type="button"
            onClick={() => {
              formik.values.notes = data?.notes;
              formik.values.description = data?.description;
              setIsSaftyInfo(false);
            }}
          >
            edit
          </button>
        ) : (
          <button
            className="w-[150px] h-[40px] border rounded bg-aqua text-white ml-6 hover:bg-aqualight hover:shadow-3xl"
            type={"submit"}
            onClick={() => {
              if (
                formik.values?.notes?.trim() !== "" &&
                formik.values?.discription?.trim() !== ""
              ) {
                if (data) {
                  updateSaftyInfo(data?.id, {
                    ...formik.values,
                    type: elm?.type,
                    tourId: tours?.tour?.id,
                  });
                } else {
                  addSaftyInfo({
                    ...formik.values,
                    type: elm?.type,
                    tourId: tours?.tour?.id,
                  });
                }
              }
            }}
          >
            Save
          </button>
        )}
      </div>
    </form>
  );
}

export default SaftyInfoInput;
