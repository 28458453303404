import React, { useState } from "react";
import Images from "../../../constant/Images";
import SelectComponent from "../../selectComponent/SelectComponent";
import { STORAGE_PATH, addPlaceinput } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import axios from "axios";
import { BaseUrl } from "../../../services/InstanceAxios";
import services from "../../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { PlacesList } from "../../../store/features/placesListSlice";
import { toast } from "react-toastify";
const AddPlacesModal = () => {
  const [File, setFile] = useState<any>({});
  const dispatch: any = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [placeData, setPlaceData] = React.useState<any>({});

  const handlechange = (e: any) => {

    setPlaceData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleAddPlaces = async (payload: any) => {
    const res = await services.postAddPlace(payload);
   
    if (res?.status == 200) {
      formik?.handleReset(formik?.values);
      toast.success(res?.data.message);
      dispatch(PlacesList());
      formik.handleReset(formik.values)
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };
  const formik : any = useFormik({
    initialValues: {
      name: "",
      // audioUrl: "",
      // videoUrl: "",
      // description: "",
      // websiteUrl: "",
      thumbnailUrl: "",
      published: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("This field is required"),
      // audioUrl: Yup.string().trim().required("This field is required"),
      // videoUrl: Yup.string().trim().required("This field is required"),
      // description: Yup.string().trim().required("This field is required"),
      // websiteUrl: Yup.string().trim().required("This field is required"),
      thumbnailUrl: Yup.string().trim().required("This field is required"),
      published: Yup.boolean()?.required("This field is required"),
    }),
    onSubmit: (values) => {
      formik?.handleReset(formik.values);

      setShowModal(false);
      handleAddPlaces({
        ...values,
        thumbnailUrl: File?.thumbnailUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        // audioUrl: File?.audioUrl?.replace(`${STORAGE_PATH}/audioUpload/`, ""),
      });
    },
  });
  const handleChangeOnFile = async (e: any) => {
  
    if ( e?.target?.files[0]?.type == "image/jpg" ||
    e?.target?.files[0]?.type == "image/png" ||
    e?.target?.files[0]?.type == "image/jpeg" ||
    e?.target?.files[0]?.type == 'image/svg' 
) {
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);
    
      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    }
    else if (e.target.accept == "audio/*") {
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadAudio(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
        
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    }
    else{
      toast.error("Only user png,jpeg,jpg,svg format")
    }
  };
  return (
    <>
      <button
        className="rounded-3xl bg-dimblack flex  justify-center items-center w-36 h-10 mt-6"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className="h-3.5 w-3.5 mr-2 font-roboto font-medium text-xl"
          src={Images.add}
          alt=""
        />
        <p className="text-white">Add Places</p>
      </button>
      {showModal ? (
        <>
          <div className="justify-center  box-border  items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[950px] h-[600px] bg-white my-6 rounded overflow-y-auto ">
              {/*content*/}

              {/*header*/}
              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Place</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button className="p-1"  onClick={() => {
                      formik.handleReset(formik.values)
                      setShowModal(false)}}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}

              <form className="px-12 py-9 " onSubmit={formik.handleSubmit}>
                <div className="">
                  {addPlaceinput.map((elm: any) => (
                    elm?.key == "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={
                          elm?.option
                        }
                      />
                    ) : (
                    <InputComponent
                      id={elm?.name}
                      handlechange={
                        elm?.type == "file"
                          ? handleChangeOnFile
                          : formik.handleChange
                      }
                      accept={elm?.accept}
                      value={
                        elm?.type == "file"
                          ? null
                          : (formik as any)?.values?.[elm?.name]
                      }
                      name={elm?.name}
                      label={elm?.label}
                      placeholder={elm?.placeHolder}
                      type={elm?.type}
                      error={(formik as any).errors[elm.name]}
                      touched={(formik as any).touched[elm.name]}
                    />
                    )
                  ))}
                </div>

                <div className="mt-9 ">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values)
                      setShowModal(false)}}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddPlacesModal;
