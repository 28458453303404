import React, { useEffect, useState } from "react";
import InputComponent from "../../component/inputComponent/InputComponent";
import { SaftyInfo } from "../../constant/string";
import SaftyInfoInput from "./SaftyInfoInput";
import services from "../../services/services";
import { useSelector } from "react-redux";

const AddSaftyInformation = () => {
  const [saftyInfoByTourId, setSaftyInfoByTourId] = useState<any>();
  const tours = useSelector((state: any) => state.singleTourSlice);
  const getSaftyInfo = async () => {
    const res: any = await services?.getSaftyInfo(tours?.tour?.id);
    if (res?.status == 200) {
      setSaftyInfoByTourId(res?.data?.data);
    }
  };
  useEffect(() => {
    getSaftyInfo();
  }, []);
  return (
    <div className="px-12 py-6">
      <h4 className="text-xl font-bold text-black "> Add Safety Information</h4>
      {SaftyInfo.map((elm: any, index: number) => (
        <SaftyInfoInput
          elm={elm}
          getSaftyInfo={getSaftyInfo}
          data={
            saftyInfoByTourId
              ? saftyInfoByTourId?.find((item: any) => item?.type == index + 1)
              : null
          }
        />
      ))}
    </div>
  );
};

export default AddSaftyInformation;
