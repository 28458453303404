const DateComponent = (props: any) => {
  console.log("props", props);
  const handleChangeStartDate = (e: any) => {
    const startDate = e?.target?.value;
    props?.selecetedStartDate(startDate);
  };
  const handleChangeEndDate = (e: any) => {
    const endDate = e?.target?.value;
    props?.selectedEndDate(endDate);
  };
  return (
    <div className="datecom flex border border-aqua rounded-3xl w-80 h-10 p-2 justify-around ">
      <input
        type="date"
        onChange={(e) => handleChangeStartDate(e)}
        className="outline-none "
        id="date"
        value={props.endDate }
      />
      <p className="border-r border-aqua"></p>
      <input
        type="date"
        onChange={(e) => handleChangeEndDate(e)}
        className="outline-none"
        id="dates"
        value={props.startDate}
      />
    </div>
  );
};

export default DateComponent;
