import React, { useEffect, useState } from "react";
import Images from "../../constant/Images";
import DeleteModalComponent from "../places/modal/DeleteModalComponent";
import UserEditModalComponent from "../places/modal/UserEditModalComponent";
import services from "../../services/services";
import { useAppDispatch } from "../../store/app/store";
import { getallUsersThank } from "../../store/features/getUsersSlice";
import { useSelector } from "react-redux";

const UsertableComponent = ({ allUser, setIsUpdate }: any) => {
  const dispatch: any = useAppDispatch();
  const [userStatus, setUserStatus] = useState(
    allUser?.map((item: any) => item.isDeleted)
  );
  const allUsers = useSelector((state: any) => state.getUsers);

  const editUserStatus = async (payload: any) => {
    const res = await services.editUser(payload);
    dispatch(getallUsersThank());
    setIsUpdate((prev: boolean) => !prev);
  };

  const handleChange = (e: any, index: any, id: any) => {
    userStatus[index] = e.target.value;
    const payload = {
      id: id,
      status: e.target.value,
    };
    editUserStatus(payload);
  };

  return (
    <>
      <table className="w-full text-sm text-left  h-40 mb-9">
        <thead className="text-xs font-Roboto font-normal text-lightgray ">
          <tr>
            <th scope="col" className="p-4">
              <div className="flex items-center"></div>
            </th>
            {/* <th scope="col" className="pl-4">
              User Profile
            </th> */}
            <th scope="col" className="">
              Name
            </th>
            <th scope="col" className="">
              Email
            </th>
            <th scope="col" className="">
              Phone No.
            </th>
            <th scope="col" className="">
              Action
            </th>
            {/* <th scope="col" className="">
              Block/Unblock
            </th> */}
          </tr>
        </thead>
        <tbody>
          {allUser &&
            allUser?.map((elm: any, index: any) => (
              <tr
                key={elm.firstName + index}
                className="bg-white border-b  border-aqua  "
              >
                <td className="w-4 p-4">
                  <div className="flex items-center"></div>
                </td>
                {/* <td className="  px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                  <img
                    className=" rounded-md"
                    src={elm.profileImageUrl}
                    alt="profile image"
                  />
                </td> */}
                <td className="">{elm.firstName}</td>
                <td className="">{elm.email}</td>
                <td className="">
                  <a
                    href="#"
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    {elm.contact}
                  </a>
                </td>
                <td className="">
                  <div className="flex items-center ">
                    <UserEditModalComponent
                      data={
                        allUsers?.users?.data?.data[index]
                          ? allUsers?.users?.data?.data[index]
                          : elm
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default UsertableComponent;
