import React, { useState } from "react";
import Images from "../../../constant/Images";
import InputComponent from "../../inputComponent/InputComponent";
import { STORAGE_PATH, addStopinput } from "../../../constant/string";
import SelectComponent from "../../selectComponent/SelectComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import services from "../../../services/services";
import { getsingleTourSlice } from "../../../store/features/singleTourSlice";
import { useAppDispatch } from "../../../store/app/store";
import { toast } from "react-toastify";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import RichTextEditor from "../../RichTextEditor";
import LanguageInputComponent from "../../inputComponent/LanguageInputComponent";
import LanguageInputComponentText from "../../inputComponent/LanguageInputComponentText";
const AddStopsComponent: any = ({ Tour }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [File, setFile] = useState<any>({});
  const [text, setLanguageText] = useState<any>({});
  const [previewLangs, setPreviewLangs] = useState<any>({});
  const [language, setLanguage] = useState<any>({});
  const [description, setDescriptionLanguage] = useState<any>({});
  const dispatch: any = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [choosenLanguage,setChoosenLanguage] = useState<any>([])
  const createStops = async (values: any) => {
    const payload = {
      ...values,
      placeId: Tour?.placeId,
      tourId: Tour?.id,
    };

    const res = await services.createStops(payload);
    if (res.status) {
      dispatch(getsingleTourSlice(Tour?.id));
      setLanguage({});
      setFile({});
      setLanguageText({});
      setDescriptionLanguage({});
      setShowModal(false);
      toast.success(res.message);
      toast.warning("You must have to click on Save button", {
        autoClose: 12000,
      });
      formik.handleReset(formik.values);
    } else {
      toast.dismiss();
      toast.error("Samething went wrong");
    }
  };

  const formik: any = useFormik({
    initialValues: {
      name:  "",
      discription:  "",
      longitude: "",
      lattitude: "",
      radius: "",
      thumbnailUrl: "",
      audioLock: "",
      isHideStop: "",
      stopLanguagesMap: "",
      stopLanguagesPreviewMap: "",
    },
    validationSchema: Yup.object({
      // name: Yup.string().trim().required("This field is required"),
    //   name: Yup.array()
    // .of(
    //   Yup.object().shape({
    //     language: Yup.string().required('Language is required'),
    //     text: Yup.string().required('Text is required')
    //   })
    // )
    // .min(1, 'At least one name is required'),
      
    // discription: Yup.array()
    // .of(
    //   Yup.object().shape({
    //     language: Yup.string().required('Language is required'),
    //     description: Yup.string().required('Description is required')
    //   })
    // )
    // .min(1, 'At least one description is required'),
      // discription: Yup.string()
      //   .trim()
      //   .trim()
      //   .required("This field is required"),
      audioLock: Yup.boolean()?.required("This field is required"),
      longitude: Yup.string()
        .trim()
        .required("This field is required")
        .matches(
          /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
          "Please Enter Valid Longitude"
        ),
      thumbnailUrl: Yup.string().trim().trim(),
      lattitude: Yup.string().trim().required("This field is required"),
      radius: Yup.string().trim().required("This field is required"),
      isHideStop: Yup.boolean()?.required("This field is required"),
    }),

    onSubmit: (values) => {
      createStops({
        ...values,
        audioLock: values?.audioLock ? true : false,
        thumbnailUrl: File?.thumbnailUrl?.replace(
          `${STORAGE_PATH}/imageUpload/`,
          ""
        ),
        name: text,
        discription: description,
        stopLanguagesMap: language,
        stopLanguagesPreviewMap: previewLangs,
      });
    },
  });
  const handleChangeOnFile = async (e: any) => {
    setLoading(true);
    if (
      e?.target?.files[0]?.type == "image/jpg" ||
      e?.target?.files[0]?.type == "image/png" ||
      e?.target?.files[0]?.type == "image/jpeg" ||
      e?.target?.files[0]?.type == "image/svg"
    ) {
      // formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      // formik.values[e.target.name] = res?.data?.data?.url[0]?.path;
      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.setFieldValue([e.target.name], res?.data?.data?.url[0]?.path);
        formik.setFieldError([e.target.name] ,"");
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    } else if (e.target.accept == "audio/*") {
      const res = await services.uploadAudio(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        formik.values[e.target.name] = res?.data?.data?.url[0]?.path?.replace(
          `${STORAGE_PATH}/audioUpload/`,
          ""
        );
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    } else {
      toast.error("Only user png,jpeg,jpg,svg format");
    }
    setLoading(false);
  };
  const deleteFile = async()=>{
    setFile({
      ...File,
      'thumbnailUrl': '',
    });
    formik.setFieldValue('thumbnailUrl', '');
  }

  return (
    <>
      <button
        className="rounded-3xl bg-dimblack flex  justify-center items-center w-36 h-10 "
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className="h-3.5 w-3.5 mr-2 font-roboto font-medium text-xl"
          src={Images.add}
          alt=""
        />
        <p className="text-white">Add Stops </p>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto">
              {/*content*/}

              {/*header*/}
              <div className="text-xl font-semibold bg-lightaqua w-[900px] h-[70px] flex justify-center items-center rounded">
                <h3 className="font-sans font-bold">Add Stops</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center relative left-[23rem]">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none"
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}

              <form onSubmit={formik.handleSubmit} className="px-12 py-9">
                <div className="">
                  {addStopinput.map((elm: any) =>
                    elm?.key == "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={elm?.option}
                      />
                    ) : elm?.type == "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        deleteFile ={deleteFile}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : elm?.type === "description" ? (
                     
                      <RichTextEditor
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          setDescriptionLanguage(data);
                        }}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ) : elm?.name === "name" ? (
                   
                      <>
                        <LanguageInputComponentText
                          key={elm?.name}
                          id={elm?.name}
                          handlechange={(data: any) => {
                            setLanguageText(data);
                          }}
                          handleSelectedLanguages={(data: any) => {
                            setChoosenLanguage((prev:any)=>[...data])
                          }}
                          accept={elm?.accept}
                          value={(formik as any)?.values?.[elm?.name]}
                          name={elm?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          type={elm?.type}
                          error={(formik as any).errors[elm.name]}
                          touched={(formik as any).touched[elm.name]}
                        />
                      </>
                    ) : elm?.name == "stopLanguagesPreviewMap" ? (
                      <LanguageInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          setPreviewLangs(data);
                        }}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ) : elm?.name == "stopLanguagesMap" ? (
                      <LanguageInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          setLanguage(data);
                        }}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ) : (
                      <InputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                </div>

                <div>{/* <AddMoreStops /> */}</div>
                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                      setLanguage({});
                      setFile({});
                      setDescriptionLanguage({});
                      setLanguageText({});
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                    disabled={loading}
                  // onClick={() => setShowModal(false)}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddStopsComponent;
