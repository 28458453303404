import React from "react";
import Images from "../../constant/Images";
import { useAppDispatch } from "../../store/app/store";
import services from "../../services/services";
import { toast } from "react-toastify";
import { getAdminsThank } from "../../store/features/getAdminsSlice";
const AdminDeleteUser = ({ id }: any) => {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch: any = useAppDispatch();

  const deleteAdmin = async(id:any) => {
   const res:any = await services.deleteAdminUser(id)
  
   if (res?.status==200) {
  toast.dismiss()
    toast?.success("Admin delete successfully")
    dispatch(getAdminsThank())
   }else{
    toast.dismiss()
    toast.error('Something went wrong ')
   }
   
   
  } 
  return (
    <>
      <img
        className="font-roboto font-medium text-xl ml-3.5 w-6 h-6 cursor-pointer"
        src={Images.remove}
        alt=""
        onClick={() => setShowModal(true)}
      />
      {showModal ? (
        <>
          <div className="justify-center  items-center flex  fixed inset-0 z-50 outline-none ">
            <div className="relative  bg-white py-6 rounded px-3 w-80 ">
              <div className="flex justify-between ">
                <p></p>
                <button
                  className="p-1"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="text-center">
                <p className="text-xl font-roboto font-normal">
                  Are you sure you want to delete this?
                </p>
              </div>
              {/*footer*/}
              <div className="mt-4 flex justify-center">
                <button
                  className=" border rounded bg-aqua text-white  px-4 py-1"
                  type="button"
                  onClick={() => {
                    // dlFn();
                    setShowModal(false);
                    deleteAdmin(id);
                  }}
                >
                  Yes
                </button>
                <button
                  className="border rounded border-aqua text-aqua px-4 py-1 ml-6"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AdminDeleteUser;
