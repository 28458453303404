import React, { useEffect, useState } from "react";
import InputComponent from "../inputComponent/InputComponent";
import Images from "../../constant/Images";
import { STORAGE_PATH, articleInput } from "../../constant/string";
import SelectComponent from "../selectComponent/SelectComponent";
import ButtonComponent from "../button/ButtonComponent";
import AddTopic from "../places/modal/AddTopic";
import services from "../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../store/app/store";
import { useSelector } from "react-redux";
import { articlesListThank } from "../../store/features/articlesListSlice";
import { ImgUrl } from "../../services/InstanceAxios";
import { topicListThank } from "../../store/features/topicListSlice";
import DeleteModalComponent from "../places/modal/DeleteModalComponent";
import EditArticle from "../places/modal/EditArticle";

const ArticleComponent = () => {
  const [File, setFile] = useState<any>({});
  const [ArticleInput, setArticleInput] = useState(articleInput);
  const articles = useSelector((state: any) => state.articlesList);
  const { Topics, isSuccess, isLoading, isError } = useSelector(
    (state: any) => state.topicList
  );
  const dispatch: any = useAppDispatch();
  const [articData, setArticData] = useState(false);
  useEffect(() => {
    // if (!topics.isSuccess) {
    dispatch(topicListThank());
    // }
    setArticleInput(articleInput);
  }, [articData]);

  useEffect(() => {
    if (!articles.isSuccess) {
      dispatch(articlesListThank());
    }
  }, []);

  const formik: any = useFormik({
    initialValues: {
      topicId: "",
      name: "",
      imageUrl: "",
      profileUrl: "",
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      topicId: Yup.string().trim().required("This field is required"),
      name: Yup.string().trim().required("This field is required"),
      imageUrl: Yup.string().trim().required("This field is required"),
      profileUrl: Yup.string().trim().required("This field is required"),
      title: Yup.string().trim().required("This field is required"),
      description: Yup.string().trim().required("This field is required"),
    
    }),
    onSubmit: (values) => {
      handleAddArticle({
        ...values,
        imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        profileUrl: File?.profileUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
      });
    },
  });

  const handleAddArticle = async (payload: any) => {
    const res = await services.postAddArticle(payload);

    if (res.status == 200) {
      toast.success(res?.data?.message);
      formik.handleReset(payload);
      dispatch(articlesListThank());
    } else {
      toast.dismiss();
      toast.error("someThing Went Wrong");
    }
  };

  const handleRemoveArticle = async (id: number) => {
    const res: any = await services.deletearticle(id);
    if (res.status === 200) {
      toast.success(res?.data.message);
      dispatch(articlesListThank());
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };
  const handleChangeOnFile = async (e: any) => {
    if (
      e?.target?.files[0]?.type == "image/jpg" ||
      e?.target?.files[0]?.type == "image/png" ||
      e?.target?.files[0]?.type == "image/jpeg" ||
      e?.target?.files[0]?.type == "image/svg"
    ) {
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    }
  };
  return (
    <div>
      <div className="w-full h-full border border-aqua rounded-xl px-12 py-11">
        <div className="flex justify-between items-center">
          <p className="font-bold font-sans text-xl ">Article</p>
          {/* <button className="bg-blue text-white py-2 px-4 rounded-lg">Add Topic</button> */}
          <AddTopic setArticData={setArticData} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto mt-8 ">
            {/* {articleInput.map((elm: any) =>
          <InputComponent label={elm.label} placeholder={elm.placeholder}/>  
          )} */}

            {ArticleInput.map((elm: any) =>
              elm?.key == "option" ? (
                <SelectComponent
                  id={elm?.name}
                  handlechange={formik.handleChange}
                  value={(formik as any)?.values?.[elm?.name]}
                  name={elm?.name}
                  label={elm?.label}
                  placeholder={elm?.placeHolder}
                  type={elm?.type}
                  error={(formik as any).errors[elm.name]}
                  touched={(formik as any).touched[elm.name]}
                  option={Topics?.data?.data}
                />
              ) : (
                <InputComponent
                  id={elm?.name}
                  handlechange={
                    elm?.type == "file"
                      ? handleChangeOnFile
                      : formik.handleChange
                  }
                  accept={elm?.accept}
                  value={
                    elm?.type == "file"
                      ? null
                      : (formik as any)?.values?.[elm?.name]
                  }
                  name={elm?.name}
                  label={elm?.label}
                  placeholder={elm?.placeHolder}
                  type={elm?.type}
                  error={(formik as any).errors[elm.name]}
                  touched={(formik as any).touched[elm.name]}
                />
              )
            )}
          </div>
          <button
            type="submit"
            className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10 "
            // onClick={() => {

            // }}
          >
            Add
          </button>
        </form>
      </div>
      <div className="w-full h-full border border-aqua rounded-xl px-12 py-11 mt-2">
        <p className="font-bold font-sans text-xl ">Uploaded Articles</p>
        {articles?.Articles?.data?.data.map((elm: any) => (
          <div className="w-full   border border-[#74A89F70]  rounded-md mt-6 px-10 py-6 'font-roboto text-xl text-[#616161]'">
                <h4 className="text-xl font-openSans text-black font-bold">{elm?.topicId}</h4>
            <div className="flex justify-between ">
              <div>
                <p className="">{elm?.name}</p>
              </div>
              <div>
                {" "}
                <button>
                  <img src={ImgUrl + elm?.imageUrl} alt="" />
                </button>
              </div>
            </div>
            <div className="flex justify-between ">
              <p className="mt-5 w-[80%]">{elm?.description}</p>
              <div className="flex align-baseline items-baseline justify-end">
                <EditArticle data={elm}  />
                <DeleteModalComponent
                  dlFn={() => {
                    handleRemoveArticle(elm?.id);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleComponent;
