import React, { useEffect, useState } from "react";
import SearchComponent from "../../component/search/SearchComponent";
import UsertableComponent from "../../component/usertable/UsertableComponent";
import services from "../../services/services";
import { useLocation } from "react-router-dom";

import { getallUsersThank } from "../../store/features/getUsersSlice";
import { useAppDispatch } from "../../store/app/store";
import { useSelector } from "react-redux";
import CreateUserModal from "../../component/places/modal/CreateUserModal";

const Users = () => {
  const {adminPermission} = useSelector((state: any) => state.counter);
  const allUsers = useSelector((state: any) => state.getUsers);
  const [allUser, setAllUser] = useState([]);
  const [filterData, setFilterData] = useState<any>();
  const [searchValue, setSearchValue] = useState();
  const dispatch: any = useAppDispatch();
  const [isUpdate,setIsUpdate]=useState(false)

  const getSearchData = (data: any, value: any) => {
    setSearchValue(value);
    setFilterData(data);
  };

  useEffect(() => {
    dispatch(getallUsersThank());
    allUsers.isSuccess && setAllUser(allUsers?.users?.data?.data);
  }, []);
  if (allUsers.isLoading) {
    return (
      <div className="flex w-full justify-center mt-8">
        <h1 className="text-aqua text-3xl">Loading...</h1>
      </div>
    );
  }
  // const { adminPermission } = useSelector((state: any) => state.counter);
  if (adminPermission?.is_admin == 2 && !adminPermission?.editUser) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        {" "}
        You don't have permission
      </h1>
    );
  }
  return (
    <div>
      <div className="flex px-12 w-full justify-between">
        <SearchComponent
          getSearchData={getSearchData}
          data={allUser.map((item: any) => ({
            ...item,
            name: item?.firstName,
          }))}
        />
        <div className="flex w-1/4 justify-end mr-4">
            <CreateUserModal />
            {/* <DropDownComponent /> */}
          </div>
      </div>
      <div className="mt-6">
        {filterData?.length || searchValue ? (
          <UsertableComponent setIsUpdate ={setIsUpdate} allUser={filterData} />
        ) : (
          <UsertableComponent setIsUpdate ={setIsUpdate} allUser={allUsers?.users?.data?.data} />
        )}
      </div>
      {!filterData?.length && searchValue ? (
        <div className="w-full h-[20vh] flex justify-center items-center">
          <h2 className="text-center text-aqua font-bold font-openSans w-full text-4xl">
            {" "}
            <span className="text-red-600">{searchValue}</span> is not available
          </h2>
        </div>
      ) : null}
    </div>
  );
};

export default Users;
