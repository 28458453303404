import { ImgUrl } from "../../services/InstanceAxios";
import PlaceEdit from "./modal/PlaceEdit";
import DeleteModalComponent from "./modal/DeleteModalComponent";
import services from "../../services/services";
import { toast } from "react-toastify";
import { PlacesList } from "../../store/features/placesListSlice";
import { useAppDispatch } from "../../store/app/store";
import { STORAGE_PATH } from "../../constant/string";

const PlacecardComponent = ({ place }: any) => {
  const dispatch: any = useAppDispatch();
  const deletePlece = async () => {
    const res: any = await services.deletePlace(place?.id);
    if (res?.data?.status) {
      toast.dismiss();
      toast.success(res?.data?.message);
      dispatch(PlacesList());
    } else {      
      toast.error(res?.data?.message);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex-wrap">
          <img
            className="h-60 w-full rounded-t-2xl"
            src={
              ImgUrl +
              (place?.thumbnailUrl &&
                place?.thumbnailUrl?.replace(`${STORAGE_PATH}/audioUpload/`, ""))
            }
            alt="Place Image"
          />
          <div className="h-20 bg-lightaqua  rounded-b-2xl flex justify-center flex-col px-6">
            <div className="font-bold text-xl font-roboto  flex justify-between items-center">
              {place?.name}
              <div className="flex items-center">
                <PlaceEdit place={place} />
                <DeleteModalComponent dlData={place} dlFn={deletePlece} />
              </div>
            </div>
            <p className="">{place?.tourDetails[0]?.count} Tours</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacecardComponent;
