import React, { useEffect, useState } from "react";
import { languages } from "../../constant/Languages";
import services from "../../services/services";
import { STORAGE_PATH } from "../../constant/string";

const LanguageInputComponent = ({
  label,
  value,
  type,
  name,
  handlechange,
  error,
  touched,
  handleBlur,
  editData,
  id,
  accept,
  choosenLanguage
}: any) => {
  const [files, setFiles] = useState<any>([]);
  const [fileLanguages, setFileLanguages] = useState<any>([]);

  const handleChangeOnLanguageFile = async (e: any) => {
    if (e.target.accept === "audio/*") {
      try {
        const res = await services.uploadAudio(e.target.files[0]);
        if (res?.data?.data?.url[0]?.path) {
          return res?.data?.data?.url[0]?.path;
        } else {
          console.error("Invalid response from the server");
          return null; // or throw an error
        }
      } catch (error) {
        console.error("Error uploading audio:", error);
        return null; // or throw an error
      }
    } else {
      console.error("Only use audio format");
      return null; // or throw an error
    }
  };

  const handleFileChange = async (event: any) => {
    const newFile: any = await handleChangeOnLanguageFile(event);
    if (newFile !== null) {
      setFiles([...files, newFile]);
    }
  };

  const removeHandler = async (index: any) => {
    const updatedFiles = [...files];
    const updatedLanguages = [...fileLanguages];
const fileToDelete = updatedFiles[index]

try {
  const audioUrl = fileToDelete;
  const res = await services.removeAudio(audioUrl);
  if(res?.status == 200){
    console.log("Audio Removed Successfully")
  }
} catch (error) {
  console.log("Error removing audio:", error);
}

    updatedFiles.splice(index, 1);
    // updatedLanguages.splice(index, 1);

    setFiles(updatedFiles);
    setFileLanguages(updatedLanguages);

    const combinedData: any[] = updatedFiles?.reduce(
      (result: any, file: any, index: any) => {
        result[index + 1] = updatedLanguages[index];
        return result;
      },
      []
    );
    handlechange(combinedData);
  };

  useEffect(()=>{
    if(choosenLanguage && choosenLanguage.length > 0){
      for(let lang of choosenLanguage){
        if(!fileLanguages.includes(lang)){
          fileLanguages.push(lang)
        }
      } 
    }
      const removedIndex = fileLanguages.findIndex((element:any) => !choosenLanguage.includes(element));
      if(removedIndex != -1){
        files.splice(removedIndex, 1);
        fileLanguages.splice(removedIndex, 1)
      }
  },[choosenLanguage])

  useEffect(() => {
    let combinedData = [];
    combinedData = files.map((path: any, index: any) => {
      const language = fileLanguages[index];
      const file = path.replace(`${STORAGE_PATH}/audioUpload/`, "");
      return { language, file };
    });
    handlechange(combinedData);
  }, [files, fileLanguages,choosenLanguage]);

  useEffect(() => {
    if (editData) {
      const parsed = JSON.parse(editData);
      const mappedFiles = parsed?.map((item: any) => item.file);
      const mappedLanguages = parsed?.map((item: any) => item.language);
      setFiles(mappedFiles);
      setFileLanguages(mappedLanguages);
    }
  }, [editData]);

  return (
    <div className="">
      <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto items-start flex">
        {label}
      </label>
      <div className="bg-gray-50 border font-roboto font-normal text-black border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none">
        <div>
          {fileLanguages.map((file: any, index: any) => (
            <div key={index} className="flex items-center mb-2">
              <span className="mr-2">{index + 1}.</span>
              <span className="px-2 py-1 bg-[#F0F0F0] border-1 m-1 font-roboto font-normal">
                {files[index]?files[index]: <label htmlFor={name} className="font-roboto font-normal">
            <span className="px-2 py-1 bg-lightaqua border-2 m-1 font-roboto font-normal cursor-pointer">
            choose File
          </span>
          <input
            className="font-roboto font-normal"
            accept={`${accept}/*`}
            id={id}
            name={name}
            onChange={handleFileChange}
            type="file"
            multiple
            onBlur={handleBlur}
            hidden
          /> 
             </label>}
              </span>
              <h2 className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none">
              {languages
              .filter(elm => elm.code === file)
              .map(filteredLang => (
              filteredLang.name
                ))}
              </h2>
               <button
                onClick={() => removeHandler(index)}
                type={"button"}
                className="ml-2 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageInputComponent;
