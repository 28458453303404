import { AxiosError, AxiosRequestConfig } from "axios";
import instance from "./InstanceAxios";
const setUpInterceptor = (isAuthenticate:string) => {
  // const token = localStorage.getItem("token")
  const handleError = async (error: AxiosError) => {  
    return Promise.reject(error);
  };

  instance.interceptors.request.use(
    async (config: any | AxiosRequestConfig) => {
      if(isAuthenticate){
        config.headers.Authorization = isAuthenticate  ;
      }
      
      return config;
    },handleError
  );

  instance.interceptors.response.use((response) => response, handleError);
};

export default setUpInterceptor;