import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
import InputComponent from "../../inputComponent/InputComponent";
import { STORAGE_PATH, addStopinput } from "../../../constant/string";
import SelectComponent from "../../selectComponent/SelectComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import services from "../../../services/services";
import { getsingleTourSlice } from "../../../store/features/singleTourSlice";
import { useAppDispatch } from "../../../store/app/store";
import { toast } from "react-toastify";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import RichTextEditor from "../../RichTextEditor";
import LanguageInputComponent from "../../inputComponent/LanguageInputComponent";
import LanguageInputComponentText from "../../inputComponent/LanguageInputComponentText";

interface EditStopDetailsProps {
  stop: any;
  visible: boolean;
  onClose: () => void;
}

const EditStopDetails = (props: EditStopDetailsProps) => {
  const { stop, visible, onClose } = props;
  const [File, setFile] = useState<any>({});
  const dispatch: any = useAppDispatch();
  const [language, setLanguage] = useState<any>({});
  const [previewLangs, setPreviewLangs] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [descriptionLanguage, setDescriptionLanguage] = useState<any>({});
  const [text, setLanguageText] = useState<any>({});
  const [choosenLanguage,setChoosenLanguage] = useState<any>([])

  const createStops = async (values: any) => {
    const payload = {
      ...values,
      placeId: stop?.placeId,
      tourId: stop?.tourId,
    };

    const res: any = await services.editStop(stop?.id, payload);

    if (res.status == 200) {
      formik.handleReset(values);
      toast.success(res?.data?.message);
      toast.warning("You must have to click on Save button", {
        autoClose: 12000,
      });
      dispatch(getsingleTourSlice(stop?.tourId));
      onClose();
    } else {
      toast.dismiss();
      toast.error("Samething went wrong");
    }
  };

  const formik: any = useFormik({
    initialValues: {
      name: stop?.name,
      discription: stop?.discription,
      longitude: stop?.longitude,
      lattitude: stop?.lattitude,
      radius: stop?.radius,
      thumbnailUrl: stop?.thumbnailUrl ? stop?.thumbnailUrl : "",
      audioLock: stop?.audioLock ? 1 : 0,
      isHideStop: stop?.isHideStop ? 1 : 0,
      stopLanguagesMap: stop?.stopLanguagesMap,
      stopLanguagesPreviewMap: stop?.stopLanguagesPreviewMap,
    },
    validationSchema: Yup.object({
      // name: Yup.string().trim().required("This field is required"),
      // discription: Yup.string().trim().required("This field is required"),
      longitude: Yup.string().trim().required("This field is required"),
      audioLock: Yup.string().trim().required("This field is required"),

      thumbnailUrl: Yup.string().trim().trim(),
      lattitude: Yup.string().trim().required("This field is required"),

      radius: Yup.string()
        .trim()
        .required("This Field  Required")
        .matches(
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
          "Please Enter Valid Radius"
        ),
      isHideStop: Yup.boolean()?.required("This field is required"),
    }),

    onSubmit: (values) => {
      createStops({
        ...values,
        audioLock: values?.audioLock ? values?.audioLock : false,
        thumbnailUrl: File?.thumbnailUrl?.replace(
          `${STORAGE_PATH}/imageUpload/`,
          ""
        ),
        stopLanguagesMap: language,
        stopLanguagesPreviewMap: previewLangs,
        name:text,
        discription:descriptionLanguage
      });
    },
    enableReinitialize: true,
  });

  const handleChangeOnFile = async (e: any) => {
    setLoading(true);
    if (
      e?.target?.files[0]?.type == "image/jpg" ||
      e?.target?.files[0]?.type == "image/png" ||
      e?.target?.files[0]?.type == "image/jpeg" ||
      e?.target?.files[0]?.type == "image/svg"
    ) {
      // formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        const newFile = res?.data?.data?.url[0]?.path
        // formik.setFieldValue([e.target.name],newFile);
        setFile({
          ...File,
          thumbnailUrl:newFile
      });
      formik.setFieldValue("thumbnailUrl", newFile);
        // console.log(File,"File")
        // formik.errors[e.target.name] = "";
        formik.setFieldError("thumbnailUrl", "")
      } else {
        // formik.values[e.target.name] = "";
        formik.setFieldValue(e.target.name, "")
        // formik.errors[e.target.name] = "Please select correct img";
        formik.setFieldError(e.target.name, "Please select correct img")
      }
    } else if (e.target.accept == "audio/*") {
      const res = await services.uploadAudio(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        formik.values[e.target.name] = res?.data?.data?.url[0]?.path?.replace(
          `${STORAGE_PATH}/audioUpload/`,
          ""
        );
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    } else {
      toast.error("Only user png,jpeg,jpg,svg format");
    }
    setLoading(false);
  };

  const deleteFile = async()=>{
    setFile({
      ...File,
      'thumbnailUrl': '',
    });
    formik.setFieldValue('thumbnailUrl', '');
  }

  return (
    <>
      {visible ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto">
              {/*content*/}

              {/*header*/}
              <div className="text-xl font-semibold bg-lightaqua w-full h-[70px] flex justify-between items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Edit Stops</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none"
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}

              <form onSubmit={formik.handleSubmit} className="px-12 py-9">
                <div className="">
                  {addStopinput.map((elm: any) =>
                    elm?.key == "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={elm?.option}
                      />
                    ) : elm?.type == "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        deleteFile ={deleteFile}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : elm?.type == "description" ? (
                      <RichTextEditor
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          const updatedData = data?.map((item: any) => {
                            return {
                              language: item?.language,
                              text: item?.text,
                            };
                          });
                          setDescriptionLanguage(updatedData);
                        }}
                        value={(formik as any)?.values?.[elm?.name]}
                        discription = {stop?.discription}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ) : elm?.name == "stopLanguagesPreviewMap" ? (
                      <LanguageInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          const updatedData = data?.map((item: any) => {
                            return {
                              language: item?.language,
                              file: item?.file?.replace(
                                `${STORAGE_PATH}/audioUpload/`,
                                ""
                              ),
                            };
                          });
                          setPreviewLangs(updatedData);
                        }}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        editData={stop?.stopLanguagesPreviewMap}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ) : elm?.name == "stopLanguagesMap" ? (
                      <LanguageInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          const updatedData = data?.map((item: any) => {
                            return {
                              language: item?.language,
                              file: item?.file?.replace(
                                `${STORAGE_PATH}/audioUpload/`,
                                ""
                              ),
                            };
                          });
                          setLanguage(updatedData);
                        }}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        editData={stop?.stopLanguagesMap}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage ={choosenLanguage}
                        setchoosenLanguageToEmpty ={() => {
                          setChoosenLanguage((prev:any)=>prev.slice(0,-1))
                        }}
                      />
                    ) :elm?.name == "name"?(
                      <LanguageInputComponentText
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          const updatedData = data?.map((item: any) => {
                            return {
                              language: item?.language,
                              text: item?.text,
                            };
                          });
                          setLanguageText(updatedData);
                        }}
                        handleSelectedLanguages={(data: any) => {
                          setChoosenLanguage((prev:any)=>[...data])
                        }}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={stop?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                      
                    ): (
                      <>
                        <InputComponent
                          key={elm?.name}
                          id={elm?.name}
                          handlechange={formik.handleChange}
                          accept={elm?.accept}
                          value={(formik as any)?.values?.[elm?.name]}
                          name={elm?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          type={elm?.type}
                          editData={stop?.stopLanguagesMap}
                          error={(formik as any).errors[elm.name]}
                          touched={(formik as any).touched[elm.name]}
                        />
                      </>
                    )
                  )}
                </div>

                <div>{/* <AddMoreStops /> */}</div>
                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                    disabled={loading}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditStopDetails;
