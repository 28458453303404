import React, { useState } from "react";
import Images from "../../constant/Images";
import InputComponent from "../../component/inputComponent/InputComponent";
import { forgotPassword, loginUser } from "../../constant/string";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../store/app/hooks";
import { StoreCookie } from "../../Auth/sessionStore";
import { Link, useNavigate } from "react-router-dom";
import services from "../../services/services";
import { useStore } from "react-redux";

import { setForgetPassword, updateAdmin } from "../../store/features/counterSlice";

const ForgotPassword = () => {
  const [isLogIn, setIsLogIn] = useState<boolean>(false);
  const [email,setEmail]=useState({})
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const validate = (values: any) => {
   
    const errors: any = {};

    if (!values.email) {
      errors.email = "This Field Is Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
   
    return errors;
  };


  const formik = useFormik({
    initialValues: {
      // password: "",
      email: "",
    },
    validate,
 
    onSubmit: (values) => {
      
      setEmail(values)
      handleGetPasswordResetLink(values);
      setIsLogIn(true);
      // handleGetPasswordResetLink(values);
      // handleLogin(values);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handleGetPasswordResetLink = async (payload: any) => {
    
   
    const res: any = await services.forgotPassword(payload);
    if (res?.response?.status == 400) {
      toast.dismiss();
      toast.error(res?.response?.data?.message);
    }
    if (res?.status == 200) {
      toast.dismiss();
      toast.success(res?.data?.message);
 
      dispatch(setForgetPassword(res?.data?.data))
      navigate("/otpverification")
    }
    
    setIsLogIn(false);
  };
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-1/3 m-auto ">
        <img src={Images.logo} alt="" />
        <h3 className="font-bold font-ProximaNovaAltBold text-7xl xl:text-6xl mt-11">
          Forgot Password
        </h3>
        <form className="w-full mt-11" onSubmit={formik.handleSubmit}>
          {forgotPassword.map((elm: any, id: number) => (
            <InputComponent
              type={elm.type}
              name={elm.name}
              key={elm.name + id}
              handleBlur={formik.handleBlur}
              touched={(formik as any).touched[elm.name]}
              label={elm.label}
              placeholder={elm.placeholder}
              handlechange={formik.handleChange}
              value={(formik as any)?.values?.[elm?.name]}
              error={(formik as any).errors?.[elm.name]}
            />
          ))}
          {/* <p className="text-xl xl:text-lg font-ProximaNovaAltRgRegular font-normal text-[#616161] mt-3.5">
            <Link to="/forgotpassword">Forgot Password?</Link>
          </p> */}
          {/* <Link to="/otpverification"> */}
          <button
            className="bg-aqualight w-full mt-12 py-4 rounded-md text-white font-bold font-ProximaNovaAltBold"
            type="submit"
            disabled={isLogIn}
            onClick={()=>{
             
              
            }}
          >
            {isLogIn ? "Processing...  " : "Get password reset link"}
          </button>
          {/* </Link> */}
          <Link to="/">
            <p className="text-xl text-black mt-3.5 text-center">
              Back to <span className="text-aqua font-bold text-xl">Login</span>
            </p>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
