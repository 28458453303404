import { useEffect, useState } from "react";
import CardsComponent from "../../component/cards/CardsComponent";
// import TableComponent from "../../component/table/TableComponent";
// import calendar from "../assets/images/svgImages/calendar.svg";
import DateComponent from "../../component/date/DateComponent";
import TableComponent from "../../component/tableComponent/TableComponent";
import { dashBoradTableHeader } from "../../constant/string";
import services from "../../services/services";
import { subMonths, startOfDay, format } from "date-fns";
import moment from "moment";
// import { increment } from "../../store/features/counterSlice";
// import { sub } from 'date-fns';
// import { format } from 'date-fns/esm';
const Dashboard = () => {
  const [dataFromChild, setDataFromChild] = useState("");
  const [dashboardData, setDashboardData] = useState<any>();
  const currentDate = moment().format("YYYY-MM-DD");
  const oneMonthAgoDate = moment().subtract(1, "month").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(oneMonthAgoDate);

  const dashboardAnalytics = async (payload: any) => {
    const res: any = await services.dashboardAnalytics(payload);
    if (res?.status === 200) {
      setDashboardData(res?.data?.data);
    }
  };
  // Get the current date
  // const currentDate = new Date();
  // currentDate.setHours(0, 0, 0, 0);
  // const today = new Date();
  // const oneMonthBefore = subMonths(today, 1);
  // const oneMonthBeforeDate = startOfDay(oneMonthBefore);
  // const formattedDate = format(oneMonthBeforeDate, 'yyyy-MM-dd');

  // console.log("Current Date:", currentDate);
  // console.log("One Month Ago Date:", oneMonthAgoDate);

  // console.log(formattedDate);
  // const today = new Date();
  // const year = currentDate.getFullYear();
  // const month = currentDate.getMonth() + 1;
  // const day = currentDate.getDate();
  // const oneMonthBefore = sub(today, { months: 1 });
  // const formattedDate = format(oneMonthBefore, 'yyyy-MM-dd');
  // console.log(formattedDate);
  // const today = new Date();
  // const oneMonthBefore = subMonths(today, 1);
  // const formattedDate = oneMonthBefore.toISOString().slice(0, 10);
  // console.log(formattedDate);
  // const year = currentDate.getFullYear();
  // const month = currentDate.getMonth() + 1;
  // const day = currentDate.getDate();
  // const oneMonthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD');
  // console.log(oneMonthAgo);
  // const formattedoneMonthAgoDate = `${year}-${
  //   month < 10 ? "0" + month : month
  // }-${day < 10 ? "0" + day : day}`;
  // const today = new Date();
  // const oneMonthLater = addMonths(today, 1);
  // const datesInRange = eachDayOfInterval({
  //   start: today,
  //   end: oneMonthLater,
  // });

  const [allTour, setAllTour] = useState([]);
  const getdata = async () => {
    try {
      const res = await services.getAllTour();
      setAllTour(res?.data?.data);
    } catch (err) {}
  };

  const handleSelectedStartDate = (date: any) => {
    console.log("date", date);
    setStartDate(date);
  };

  const handleSelectedEndDate = (date: any) => {
    setEndDate(date);
  };

  const getDashboardAnalytics = () => {
    let payload = { startDate: startDate, endDate: endDate };
    dashboardAnalytics(payload);
  };
  useEffect(() => {
    getdata();
    // console.log("getdata",getdata);
    
    dashboardAnalytics({
      startDate: currentDate,
      endDate: oneMonthAgoDate,
    });
  }, []);
  return (
    <>
      <div className="w-full px-12 py-12">
        <div className=" flex justify-between ">
          <div>
            <h4 className=" font-sans font-bold text-xl">Overview</h4>
          </div>
          <div className="flex ">
            <p className="mt-2.5 mr-3 font-roboto font-normal text-base">
              Date Range
            </p>
            <DateComponent
              selecetedStartDate={handleSelectedStartDate}
              selectedEndDate={handleSelectedEndDate}
              // onData ={handleSelectedStartDate.selectStartDate()}
              startDate={startDate}
              endDate={endDate}
            />
            <button
              onClick={getDashboardAnalytics}
              className="bg-aqua text-black text-xl font-ProximaNovaAltRgRegular rounded-full px-4 ml-2"
            >
              Search
            </button>
          </div>
        </div>
        <div className="flex justify-between mt-9">
          <CardsComponent data={dashboardData} />
        </div>
        {/* <TableComponent /> */}
        <div className="mt-5">
          <div className="flex items-center justify-between  bg-white dark:bg-gray-900">
            <h4 className="mt-9 font-sans text-xl font-bold text-dimblack">
              Total Selling Tours
            </h4>
            {/* <DropDownComponent /> */}
          </div>
          <div className="mt-5">
            {/* {allTour &&
              allTour?.map((elm: any) => ( */}
            <TableComponent
              columns={dashBoradTableHeader}
              allTour={dashboardData?.sortable}
            />
            {/* ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
