import Images from "../../constant/Images";
import { ImgUrl } from "../../services/InstanceAxios";
import { useNavigate } from "react-router-dom";
import { setId } from "../../store/features/singleTourSlice";
import { toast } from "react-toastify";
import services from "../../services/services";
import DeleteModalComponent from "../places/modal/DeleteModalComponent";
import { toursThank } from "../../store/features/toursSlice";
import { useAppDispatch } from "../../store/app/store";
import { getallBundlesThank } from "../../store/features/getBundlesSlice";
import EditTourModal from "../places/modal/EditTourModal";
import EditBundule from "../places/modal/EditBundule";
import { STORAGE_PATH } from "../../constant/string";
import { getTourName } from "../../services/Tour";
// import {} from  "../../store/features/singleTourSlice"
const TourscardComponent = ({ bundle, elm, allTour }: any) => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const handleNavigate = (Tour: any) => {
    if (Tour) {
      dispatch(setId(Tour));
      navigate("/stopList");
    }
  };
  const deletePlece = async () => {
    const res: any = await services.deleteTour(allTour?.id);

    if (res?.status == 200) {
      toast.dismiss();
      toast.success(res?.data.message);
      dispatch(toursThank());
    } else {
      toast.error("SomeThing went wrong");
    }
  };
  const deleteBundle = async () => {
    const res: any = await services.deleteBundle(bundle?.id);

    if (res?.status == 200) {
      toast.dismiss();
      toast.success(res?.data.message);
      dispatch(getallBundlesThank());
    } else {
      toast.error("SomeThing went wrong");
    }
  };

  if (allTour) {
    return (
      <div className="mt-9 box-border h-[600px] min-h-[600px] max-h-[600px] overflow-auto">
        <img
          className=" w-full rounded-t-2xl h-[173px]"
          src={
            ImgUrl +
            allTour?.thumbnailUrl?.replace(`${STORAGE_PATH}/imageUpload/`, "")
          }
          alt="Sunset in the mountains"
        />
        {/* <div className="border border-aqua h-[183px] w-[294px] rounded-b-2xl"> */}
        <div className="border border-aqua rounded-b-2xl pt-3 px-5 pb-10 max-h-[400px] min-h-[400px]">
          <div className="flex justify-between">
            <p className="font-bold text-xl font-roboto w-4/5 xl:text-sm lg:text-sm md:text-sm ">
              {getTourName(allTour?.name, "en")}
            </p>
            {/* <div onClick={handleNavigate.bind(this, allTour)}>
                <img src={Images.edit} alt="edit" />
              </div> */}

            <div className="flex w-1/5">
              {/* <PlaceEdit place={place} /> */}
              {/* <div onClick={handleNavigate.bind(this, allTour)}>
                  <img src={Images.edit} alt="edit" />
                </div> */}
              <EditTourModal data={allTour} className="w-3.5 h-3.5" />
              <DeleteModalComponent
                dlFn={deletePlece}
                className="w-3.5 h-3.5"
              />
            </div>
          </div>
          <p className="font-Opensans font-normal text-base xl:text-sm lg:text-sm md:text-sm">
            {allTour?.title}
          </p>
          <div className="flex font-roboto font-normal text-base items-center">
            <h3 className="font-bold text-aqua text-xl xl:text-xl xxl:text-xl xxxl:text-xl font-Segoe-UI">
              {/* $ 19.99 */}
              {" $ "}
              {/* <span className="">{allTour?.retailPrice}</span>{" "} */}
              {allTour?.sellingPrice
                ? allTour?.sellingPrice
                : allTour?.retailPrice + " "}
            </h3>
            <span className="text-xs line-through ml-2">
              {" "}
              {/* {" $ "} */}
              {/* {allTour?.sellingPrice
                ? allTour?.sellingPrice
                : allTour?.retailPrice + " "} */}
              <span className="line-through">
                {allTour?.sellingPrice && allTour?.retailPrice}
              </span>{" "}
            </span>
            <p className="ml-4 text-xs">
              {allTour?.sellingPrice && "Save"}
              {allTour?.sellingPrice &&
                allTour?.sellingPrice &&
                (
                  ((allTour?.retailPrice - allTour?.sellingPrice) /
                    allTour?.retailPrice) *
                  100
                ).toFixed(2) + "%"}
            </p>
          </div>
          <div className="flex my-3">
            <div className="w-1/2 flex">
              <img className="h-5 w-5" src={Images.smallcar} alt="" />
              <p className="font-roboto font-normal text-sm ml-1.5">
                {allTour?.type == 1 ? "Walking" : "Driving"}
              </p>
            </div>
            <div className="w-1/2 flex">
              <img className="h-5 w-5" src={Images.clock} alt="" />
              <p className=" font-roboto font-normal text-sm ml-1.5">
                Each tour {allTour?.duration}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 flex">
              <img className="h-5 w-5" src={Images.flag} alt="" />
              <p className="ml-1.5 font-roboto font-normal text-sm  ">
                {allTour?.narrationPoints} Narration Points{" "}
              </p>
            </div>
            <div className="w-1/2 flex">
              <img className="h-5 w-5" src={Images.stars} alt="" />
              <p className=" font-roboto font-normal text-sm ml-1.5">
                {allTour?.reviewCount} Reviews
              </p>
            </div>
          </div>
        </div>
        <div className="-mt-4">
          <button
            className="bg-aqua text-white rounded-full py-3.5 px-9 font-roboto font-normal xl:py-2.5 -mt-4 ml-6"
            onClick={handleNavigate.bind(this, allTour)}
          >
            View Stops
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="mt-9 ">
        <div className="">
          <img
            className=" w-full rounded-t-2xl h-[173px] "
            src={ImgUrl + bundle?.imageUrl}
            alt="Sunset in the mountains"
          />
          {/* <div className="border border-aqua h-[183px] w-[294px] rounded-b-2xl"> */}
          <div className="border border-aqua rounded-b-2xl px-5 py-3 max-h-[400px] min-h-[400px]">
            <div className="flex justify-between  items-center ">
              <p className="font-bold text-xl font-roboto">{bundle?.name}</p>
              {/* <Link to="/StopList">
                <img src={Images.edit} alt="edit" />
              </Link> */}
              <div className="flex items-center">
                {/* <PlaceEdit place={place} /> */}
                <EditBundule data={bundle} className="w-3.5 h-3.5" />
                <DeleteModalComponent
                  dlFn={deleteBundle}
                  className="w-3.5 h-3.5"
                />
              </div>
            </div>
            <p className="font-opensans font-normal text-base">
              {/* {allTour?.title} */}
            </p>
            <div className="flex font-roboto font-normal text-base items-center ">
              <h3 className="font-bold text-aqua text-xl font-Segoe-UI">
              {/* {allTour?.sellingPrice
                ? allTour?.sellingPrice
                : allTour?.retailPrice + " "} */}

                {" $ "}
                {bundle?.sellingPrice  ? bundle?.sellingPrice : bundle?.retailPrice + " "}
                {/* $ 19.99 */}
                {/* $ {bundle?.retailPrice} */}
              </h3>
              {bundle?.sellingPrice !== bundle?.retailPrice && <><span className="ml-4 line-through text-xs flex">
                {/* {bundle?.sellingPrice} */}
                {/* {allTour?.sellingPrice && allTour?.retailPrice} */}

                {bundle?.sellingPrice && bundle?.retailPrice}
              </span>
              <p className="ml-4 text-xs">
                
                {bundle?.sellingPrice && "Save"}
                {bundle?.sellingPrice &&
                  bundle?.sellingPrice &&
                  (
                    ((bundle?.retailPrice - bundle?.sellingPrice) /
                      bundle?.retailPrice) *
                    100
                  ).toFixed(2) + "%"}
              </p></>}
            </div>
            {/* {allTour?.sellingPrice && "Save"}
              {  allTour?.sellingPrice && (allTour?.sellingPrice && ((
                ((allTour?.retailPrice - allTour?.sellingPrice) /
                  allTour?.retailPrice) *
                100
              ).toFixed(2))+"%")
              } */}
            {/* <div className="flex my-3">
              <div className="w-1/2 flex"><img className="h-5 w-5" src={Images.smallcar} alt="" />
              <p className="font-roboto font-normal text-sm ml-1.5">
                {" "}
                {bundle?.status == 1 ? "Walking" : "Driving"}{" "}
              </p></div>
              <div className="w-1/2 flex">
              <img className="h-5 w-5 " src={Images.clock} alt="" />
              <p className=" font-roboto font-normal text-sm ml-1.5">
                Each tour {allTour?.duration}
              </p>
              </div>
              
            </div> */}
            {/* <div className="flex ">
              <div className="w-1/2 flex">
              <img className="h-5 w-5" src={Images.flag} alt="" />
              <p className="ml-1.5 font-roboto font-normal text-sm w-20 ">
              {bundle?.narrationPoints}Narration Points
              </p>
              </div>
              <div className="w-1/2 flex">
              <img className="h-5 w-5 " src={Images.stars} alt="" />
              <p className=" font-roboto font-normal text-sm ml-1.5">
                150+ Reviews
              </p>
              </div>
             
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TourscardComponent;
