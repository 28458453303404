import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

const placesListSlice = createSlice({
  name: "placesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PlacesList.pending, (state: any) => {
        state.isLoading = true;
      })
      .addCase(PlacesList.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(PlacesList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.data = [];
      });
  },
});

export const PlacesList = createAsyncThunk(
  "placesList/getPlaceList",
  async (_, { dispatch }) => {
    return await services.getAllPlace();
  }
);

export default placesListSlice.reducer;

